html {
  box-sizing: border-box;
  --mdb-body-font-size: 14px !important;
}

body,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
}

body {
  font-size: 16px;
}

.gym-half img {
  margin-bottom: -6px;
  margin-top: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.gym-half img:hover {
  opacity: 1;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  user-select: auto !important;
}

.justify-end {
  justify-content: end;
}

.m-0 {
  margin: 0px;
}

/*---------------- SMS ------------*/

/*-------------table------------*/
.FixedtableHeader {
  overflow: auto;
  /*height: 400px;*/
}

.FixedtableHeader thead th,
.datatable-header tr {
  position: sticky !important;
  top: -1 !important;
  text-align: start;
}

/* Just common table stuff. Really. */
.FixedtableHeader table {
  border-collapse: collapse;
  width: 100%;
}

.FixedtableHeader th,
.FixedtableHeader td {
  padding: 8px 16px;
  /*border-right: 1px solid #ccc;*/
}

.FixedtableHeader th {
  background: #fff;
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
}

.FixedtableHeader tr:nth-child(even) {
  /*background: #f5f5f5;*/
}

/*-----------End table----------*/
.remaindersmain {
  margin: 20px 0 0px 0px;
  padding: 0px;
  display: flex;
  width: 32%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px #ddd;
}

/* .remaindersmains {
    margin: 20px 0 0px 0px;
    padding: 0px;
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 5px #ddd;
} */

.r-active {
  color: #193b8b;
  padding: 10px;
  border-right: 1px solid #eee;
}

.remainders-middle {
  text-align: center;
  border-right: 1px solid #ebebeb;
  background-color: #f9f9f9;
}

.r-active {
  color: #193b8b;
  cursor: pointer;
}

.payments {}

.payments ul {
  list-style-type: none;
  padding: 0 0 0 0px;
}

.payments ul li {
  padding: 15px 0 0 0;
}

.savebutton {
  background: #193b8b;
  border: 1px solid #193b8b;
  border-radius: 5px;
  padding: 5px 40px 5px 40px;
  color: #fff;
  margin: 0 0 0 18px;
  cursor: pointer;
}

.checkinbuttonmain {
  box-shadow: 0px 1px 5px #ddd;
  padding: 18px 0px;
  display: flex;
  /* align-items: center; */
  margin: 15px 0px;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.usercar-prev {
  box-shadow: 0px 1px 5px #ddd;
  padding: 15px;
  display: flex;
  align-items: center;
  margin: 10px 0px 10px 0px;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
}

.insidecheck {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: flex-start;
  justify-content: space-between;
}


.boxpadding {
  padding: 1.2rem;
}

.checkinbutton {
  background: #30d500;
  border: 1px solid #30d500;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
}

.mainbox {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.mainboxsecond {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.checkinbuttonout {
  background: #ff6a6a;
  border: 1px solid #ff6a6a;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;

}

.checkindate,
.logtime,
.checkinbutton,
.checkinbuttonout {
  width: 85px !important;
}

.checkindate~span.pt-1.text-end {
  background: #fff;
  /* margin: 3px 0 2px 0; */
  padding: 0.4em;
  cursor: pointer;
  width: fit-content;
  border: 1px solid #d7dde8;
  border-radius: 5px;
}

.checktime {
  font-size: 12px;
}

.daterangemain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.datechange {
  display: flex;
  flex-direction: column;
}

.datefont {
  font-size: 12px;
}

.dateranges {
  display: flex;
  align-items: center;
}

.datetext {
  margin: 0 40px 0 0;
}

.viewcolor {
  color: #193b8b;
  cursor: pointer;
}

.checkinbuttonouttable {
  background: #193b8b;
  border: 1px solid #193b8b;
  color: #fff;
  padding: 6px 10px 6px 10px;
  /* height: 43px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96px;
  font-size: 12px;
}

.checkinbuttonoutred {
  background: #ff6a6a;
  border: 1px solid #ff6a6a;
  color: #fff;
  padding: 6px 10px 6px 10px;
  /* height: 43px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96px;
  font-size: 12px;
}

.createplan {
  border: 1px solid #ddd;
  /* box-shadow: 0px 1px 5px #ddd; */
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0px 0px 0px;
}

.createplan:hover {
  color: #000 !important;
  background-color: #ccc !important;
}

.createplanmain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timegym {
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
  padding: 10px 30px;
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timegym-home {
  /* border: 1px solid #eee; */
  /* box-shadow: 0px 1px 5px #ddd; */
  padding: 0px 3px;
  margin: 10px 0 0 0;
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.timegymslot {
  border: 0px solid #eee;
  box-shadow: 0px 1px 5px #ededed;
  padding: 18px;
  margin: 10px 0px 13px 0px;
  /* height: 90px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .slotmain {
    display: flex;
    flex-wrap: wrap;
} */

.days {
  font-size: 12px;
}

.gym-bar-itemstext {
  text-align: center;
  width: 25%;
}

.flex-end {
  align-items: center;
}

.text-end {
  text-align: end !important;
}

.text-left {
  text-align: left !important;
}

.size {
  border: 1px solid #9c9c9c;
  border-radius: 16px 16px 16px 16px;
  padding: 2px 10px 2px 10px;
  font-size: 12px;
  display: inline-block;
}

.slotpopup {
  width: 50%;
  border: 1px solid #dbdbdb;
  padding: 20px;
}

.slotpopups {
  width: 50%;

  padding: 20px 0px 0px 0px;
}

.slotpopups a.btn {
  margin-top: 20px;
}

.addslot {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 30px 0;
}

.slotname {
  display: -webkit-box;
}

.slotname label {
  width: 200px;
  display: inline-block;
  line-height: 2.1;
}

.slotname input {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 5px;
  margin: 0px 0 22px 0;
}

.slotdrop {
  border: 1px solid #dbdbdb !important;
  border-radius: 5px;
  width: 74px;
  margin: 0 13px 0 0;
  padding: 5px;
}

.slotdrops {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  width: 74px !important;
  margin: 0 16px 20px 0px;
}

.addbutton {
  background: #193b8b;
  display: inline-block;
  border: 1px solid #193b8b;
  color: #fff;
  padding: 5px 20px 5px 20px;
  cursor: pointer;
}

.addbuttons {
  border: 1px solid #777777;
  display: inline-block;
  color: #000;
  padding: 5px 20px 5px 20px;
  margin: 0 0 0 25px;
  cursor: pointer;
}

.addslotbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0 0;
}

.addslotbuttons {
  display: flex;

  margin: 30px 0 0 0;
}

.slotselect {
  width: 214px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 5px;
  margin: 0px 0 22px 0;
}

.slotselect-w105 {
  width: 107px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 5px;
  margin: 0px 0 22px 0;
}

.addic {
  margin: 0 10px 0 0;
}

.duepayment {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.duepaymentmain {
  border: 1px solid #dbdbdb;
  width: 30%;
  padding: 20px;
  /* display: flex; */
  justify-content: center;
}

.duepaymentmain-payments {
  border: 1px solid #dbdbdb;
  /* width: 30%; */
  padding: 20px;
  /* display: flex; */
  justify-content: center;
  display: flex;
}

.duepaymentmainsingle {
  border: 1px solid #dbdbdb;
  width: 80%;
  padding: 20px;
}

.duepaymentradio {
  padding: 10px;
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.duepaymentradiopay {
  padding: 10px;
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #193b8b;
  color: #fff;
  cursor: pointer;
}

.pd_0 {
  padding: 0px !important;
}

.slotinput {
  width: 75px;
}

.slotdrops {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  width: 91px;
  padding: 5px;
}

.slotcheckboxmain {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

.slotcheckbox {
  padding: 0px !important;
  margin: 0px 4px 0px 0px !important;
  width: 20px;
  height: 15px;
}

.slotpopupsbox {
  width: 100%;
  padding: 20px;
}

.slotcal {
  display: flex;
  align-items: center;
  padding: 25px;
}

.allowedslotsbox {
  display: flex;
  align-items: center;
}

.slottime {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0 0 0 5px;
}

.allowedslotsmain {
  background: #f2f2f2;
  padding: 5px;
  border: 1px solid #dbdbdb;
  margin: 0 0 30px 0;
  width: 186px;
}

.allowedslotshover {
  background: #e3ebf7;
  padding: 5px;
  width: 176px;
  border: 1px solid #dbdbdb;
  margin: 0 0 0 0;
  color: #000;
  align-items: center;
}

.allowedslotsall {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.w-inherit {
  width: inherit !important;
}

.m-inherit {
  margin: inherit !important;
}

.allowedslots {
  margin: 30px 0 0 0;
}

.slotto {
  margin: 6px 12px 0 0px;
}

.splitpaymentright {
  padding: 0 0 0 30px;
}

.splitpayment {
  width: 40%;
  width: 45%;
  border-right: 1px solid #dbdbdb;
  padding: 0 30px 0px 0;
}

.splitpay {
  margin: 0 0 15px 0;
  font-size: 16px;
}

.splitenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.splitinput {
  width: 50px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #000;
}

.splitinputupi {
  width: 174px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #000;
}

.splitinputwallet {
  width: 100px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #000;
}

.thanksmain {
  text-align: center;
}

.thankscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.paymentsuccess {
  font-size: 17px;
  font-weight: bold;
  margin: 8px 0 0 0;
}

.w-payment {
  width: 70px;
}

.paymentrate {
  font-size: 22px;
  margin: 0 0 10px 0;
}

/*----------------End SMS ------------*/

/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

summary {
  display: list-item;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

progress {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 57% !important;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

figure {
  margin: 1em 40px;
}

img {
  border-style: none;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea,
optgroup {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: bold;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* End extract */
html,
body {
  font-family: Verdana, sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

html {
  overflow-x: hidden;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

.gym-serif {
  font-family: serif;
}

.gym-sans-serif {
  font-family: sans-serif;
}

.gym-cursive {
  font-family: cursive;
}

.gym-monospace {
  font-family: monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI", Arial, sans-serif;
  font-weight: 400;
  margin: 10px 0;
  line-height: 30px;
}

.gym-wide {
  letter-spacing: 4px;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 20px 0;
}

.gym-image {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
}

a {
  color: inherit;
}

.gym-table,
.gym-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
}

.gym-table-all {
  border: 1px solid #ccc;
}

.gym-bordered tr,
.gym-table-all tr {
  border-bottom: 1px solid #ddd;
}

.gym-striped tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

.gym-table-all tr:nth-child(odd) {
  background-color: #fff;
}

.gym-table-all tr:nth-child(even) {
  background-color: #f1f1f1;
}

.gym-hoverable tbody tr:hover,
.gym-ul.gym-hoverable li:hover {
  background-color: #ccc;
}

.gym-centered tr th,
.gym-centered tr td {
  text-align: center;
}

.gym-table td,
.gym-table th,
.gym-table-all td,
.gym-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top;
}

.gym-table th:first-child,
.gym-table td:first-child,
.gym-table-all th:first-child,
.gym-table-all td:first-child {
  padding-left: 16px;
}

.gym-btn,
.gym-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.gym-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .gym-btn,
.gym-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

.gym-disabled,
.gym-btn:disabled,
.gym-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.gym-disabled *,
:disabled * {
  pointer-events: none;
}

.gym-btn.gym-disabled:hover,
.gym-btn:disabled:hover {
  box-shadow: none;
}

.gym-badge,
.gym-tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.gym-badge {
  border-radius: 50%;
}

.gym-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.gym-ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.gym-ul li:last-child {
  border-bottom: none;
}

.gym-tooltip,
.gym-display-container {
  position: relative;
}

.gym-tooltip .gym-text {
  display: none;
}

.gym-tooltip:hover .gym-text {
  display: inline-block;
}

.gym-ripple:active {
  opacity: 0.5;
}

.gym-ripple {
  transition: opacity 0s;
}

.gym-input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.gym-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}

.gym-textarea {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}

.gym-dropdown-click,
.gym-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.gym-dropdown-hover:hover .gym-dropdown-content {
  display: block;
}

.gym-dropdown-hover:first-child,
.gym-dropdown-click:hover {
  background-color: #ccc;
  color: #000;
}

.gym-dropdown-hover:hover>.gym-button:first-child,
.gym-dropdown-click:hover>.gym-button:first-child {
  background-color: #ccc;
  color: #000;
}

.gym-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 160px;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.gym-check,
.gym-radio {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
}

.gym-sidebar {
  height: 100%;
  width: 200px;
  background-color: #fff;
  position: fixed !important;
  z-index: 1;
  overflow: auto;
}

.gym-bar-block .gym-dropdown-hover,
.gym-bar-block .gym-dropdown-click {
  width: 100%;
}

.gym-bar-block .gym-dropdown-hover .gym-dropdown-content,
.gym-bar-block .gym-dropdown-click .gym-dropdown-content {
  min-width: 100%;
}

.gym-bar-block .gym-dropdown-hover .gym-button,
.gym-bar-block .gym-dropdown-click .gym-button {
  width: 100%;
  text-align: left;
  padding: 8px 16px;
}

.gym-main,
#main {
  transition: margin-left 0.4s;
  padding-top: 20px;
}

.gym-modal {
  z-index: 3;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.gym-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}

.gym-bar {
  width: 100%;
  overflow: hidden;
}

.gym-center .gym-bar {
  display: inline-block;
  width: auto;
}

.gym-bar .gym-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0;
}

.gym-bar .gym-dropdown-hover,
.gym-bar .gym-dropdown-click {
  position: static;
  float: left;
}

.gym-bar .gym-button {
  white-space: normal;
}

.gym-bar-block .gym-bar-item {
  width: 100%;
  display: block;
  padding: 8px 10px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
  padding-left: 40px;
  line-height: 24px;
  transition: all 1.5s ease-out;
}

.gym-bar-block.gym-center .gym-bar-item {
  text-align: center;
}

.gym-block {
  display: block;
  width: 100%;
}

.gym-responsive {
  display: block;
  overflow-x: auto;
}

.gym-container:after,
.gym-container:before,
.gym-panel:after,
.gym-panel:before,
.gym-row:after,
.gym-row:before,
.gym-row-padding:after,
.gym-row-padding:before,
.gym-cell-row:before,
.gym-cell-row:after,
.gym-clear:after,
.gym-clear:before,
.gym-bar:before,
.gym-bar:after {
  content: "";
  display: table;
  clear: both;
}

.gym-col,
.gym-half,
.gym-third,
.gym-twothird,
.gym-threequarter,
.gym-quarter {
  float: left;
  width: 100%;
}

.gym-col.s1 {
  width: 8.33333%;
}

.gym-col.s2 {
  width: 16.66666%;
}

.gym-col.s3 {
  width: 24.99999%;
}

.gym-col.s4 {
  width: 33.33333%;
}

.gym-col.s5 {
  width: 41.66666%;
}

.gym-col.s6 {
  width: 49.99999%;
}

.gym-col.s7 {
  width: 58.33333%;
}

.gym-col.s8 {
  width: 66.66666%;
}

.gym-col.s9 {
  width: 74.99999%;
}

.gym-col.s10 {
  width: 83.33333%;
}

.gym-col.s11 {
  width: 91.66666%;
}

.gym-col.s12 {
  width: 99.99999%;
}

@media (min-width: 601px) {
  .gym-col.m1 {
    width: 8.33333%;
  }

  .gym-col.m2 {
    width: 16.66666%;
  }

  .page-loader {
    position: fixed;
  }

  .ml0 {
    margin-left: 0px !important;
  }

  .gym-col.m3,
  .gym-quarter {
    width: 24.99999%;
  }

  .gym-col.m4,
  .gym-third {
    width: 33.33333%;
  }

  .gym-col.m5 {
    width: 41.66666%;
  }

  .gym-col.m6,
  .gym-half {
    width: 32.99999%;
  }

  .gym-col.m7 {
    width: 58.33333%;
  }

  .gym-col.m8,
  .gym-twothird {
    width: 66.66666%;
  }

  .gym-col.m9,
  .gym-threequarter {
    width: 74.99999%;
  }

  .gym-col.m10 {
    width: 83.33333%;
  }

  .gym-col.m11 {
    width: 91.66666%;
  }

  .gym-col.m12 {
    width: 99.99999%;
  }
}

@media (min-width: 993px) {
  .gym-col.l1 {
    width: 8.33333%;
  }

  .gym-col.l2 {
    width: 16.66666%;
  }

  .gym-col.l3 {
    width: 24.99999%;
  }

  .gym-col.l4 {
    width: 33.33333%;
  }

  .gym-col.l5 {
    width: 41.66666%;
  }

  .gym-col.l6 {
    width: 49.99999%;
  }

  .gym-col.l7 {
    width: 58.33333%;
  }

  .gym-col.l8 {
    width: 66.66666%;
  }

  .gym-col.l9 {
    width: 74.99999%;
  }

  .gym-col.l10 {
    width: 83.33333%;
  }

  .gym-col.l11 {
    width: 91.66666%;
  }

  .gym-col.l12 {
    width: 99.99999%;
  }
}

.gym-rest {
  overflow: hidden;
}

.gym-stretch {
  margin-left: -16px;
  margin-right: -16px;
}

.gym-content,
.gym-auto {
  margin-left: auto;
  margin-right: auto;
}

.gym-content {
  max-width: 980px;
}

.gym-auto {
  max-width: 1140px;
}

.gym-cell-row {
  display: table;
  width: 100%;
}

.gym-cell {
  display: table-cell;
}

.gym-cell-top {
  vertical-align: top;
}

.gym-cell-middle {
  vertical-align: middle;
}

.gym-cell-bottom {
  vertical-align: bottom;
}

.gym-hide {
  display: none !important;
}

.gym-show-block,
.gym-show {
  display: block !important;
}

.gym-show-inline-block {
  display: inline-block !important;
}

@media (max-width: 1205px) {
  .gym-auto {
    max-width: 95%;
  }

  .waveForm {
    width: 80% !important;
  }

}

@media (max-width: 600px) {

  .waveForm {
    width: 80% !important;
  }

  .gym-modal-content {
    margin: 0 10px;
    width: auto !important;
  }

  .gym-modal {
    padding-top: 30px;
  }

  .gym-dropdown-hover.gym-mobile .gym-dropdown-content,
  .gym-dropdown-click.gym-mobile .gym-dropdown-content {
    position: relative;
  }

  .gym-hide-small {
    display: none !important;
  }

  .gym-mobile {
    display: block;
    width: 100% !important;
  }

  .gym-bar-item.gym-mobile,
  .gym-dropdown-hover.gym-mobile,
  .gym-dropdown-click.gym-mobile {
    text-align: center;
  }

  .gym-dropdown-hover.gym-mobile,
  .gym-dropdown-hover.gym-mobile .gym-btn,
  .gym-dropdown-hover.gym-mobile .gym-button,
  .gym-dropdown-click.gym-mobile,
  .gym-dropdown-click.gym-mobile .gym-btn,
  .gym-dropdown-click.gym-mobile .gym-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .gym-modal-content {
    width: 500px;
  }

  .cover-user .cover-user-img {
    align-items: center !important;
  }

  .gym-modal {
    padding-top: 50px;
  }

  .usersearch {
    position: relative !important;
    width: 125px !important;
    min-height: 40px;
    max-height: 150px;
    z-index: 9999;
    overflow-y: auto;
  }
}

@media (min-width: 993px) {
  .gym-modal-content {
    width: 900px;
  }

  .gym-hide-large {
    display: none !important;
  }

  .gym-sidebar.gym-collapse {
    display: block !important;
  }
}

@media (max-width: 992px) and (min-width: 601px) {
  .gym-hide-medium {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .gym-sidebar.gym-collapse {
    display: none;
  }

  .flex-mob-wrap {
    flex-wrap: wrap;
  }

  .gym-main {
    margin-left: 10px !important;
    margin-right: 0 !important;
    margin-top: 70px !important;
  }

  .gym-auto {
    max-width: 100%;
  }
}

.gym-top,
.gym-bottom {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.gym-top {
  top: 0;
}

.gym-bottom {
  bottom: 0;
}

.gym-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.gym-display-topleft {
  position: absolute;
  left: 0;
  top: 0;
}

.gym-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}

.gym-display-bottomleft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.gym-display-bottomright {
  position: absolute;
  right: 0;
  bottom: 0;
}

.gym-display-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.gym-display-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(-0%, -50%);
}

.gym-display-right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}

.gym-display-topmiddle {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}

.gym-display-bottommiddle {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}

.gym-display-container:hover .gym-display-hover {
  display: block;
}

.gym-display-container:hover span.gym-display-hover {
  display: inline-block;
}

.gym-display-hover {
  display: none;
}

.gym-display-position {
  position: absolute;
}

.gym-circle {
  border-radius: 50%;
}

.gym-round-small {
  border-radius: 2px;
}

.gym-round,
.gym-round-medium {
  border-radius: 4px;
}

.gym-round-large {
  border-radius: 8px;
}

.gym-round-xlarge {
  border-radius: 16px;
}

.gym-round-xxlarge {
  border-radius: 32px;
}

.gym-row-padding,
.gym-row-padding>.gym-half,
.gym-row-padding>.gym-third,
.gym-row-padding>.gym-twothird,
.gym-row-padding>.gym-threequarter,
.gym-row-padding>.gym-quarter,
.gym-row-padding>.gym-col {
  padding: 0 8px;
}

.gym-container,
.gym-panel {
  padding: 0.01em 16px;
}

.gym-panel {
  margin-top: 16px;
  margin-bottom: 16px;
}

.gym-code,
.gym-codespan {
  font-family: Consolas, "courier new";
  font-size: 16px;
}

.gym-code {
  width: auto;
  background-color: #fff;
  padding: 8px 12px;
  border-left: 4px solid #4caf50;
  word-wrap: break-word;
}

.gym-codespan {
  color: crimson;
  background-color: #f1f1f1;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 110%;
}

.gym-card,
.gym-card-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.gym-card-4,
.gym-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

.gym-spin {
  animation: gym-spin 2s infinite linear;
}

@keyframes gym-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.gym-animate-fading {
  animation: fading 10s infinite;
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.gym-animate-opacity {
  animation: opac 0.8s;
}

@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.gym-animate-top {
  position: relative;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.gym-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.gym-animate-right {
  position: relative;
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.gym-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.gym-animate-zoom {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.gym-animate-input {
  transition: width 0.4s ease-in-out;
}

.gym-animate-input:focus {
  width: 100% !important;
}

.gym-opacity,
.gym-hover-opacity:hover {
  opacity: 0.6;
}

.gym-opacity-off,
.gym-hover-opacity-off:hover {
  opacity: 1;
}

.gym-opacity-max {
  opacity: 0.25;
}

.gym-opacity-min {
  opacity: 0.75;
}

.gym-greyscale-max,
.gym-grayscale-max,
.gym-hover-greyscale:hover,
.gym-hover-grayscale:hover {
  filter: grayscale(100%);
}

.gym-greyscale,
.gym-grayscale {
  filter: grayscale(75%);
}

.gym-greyscale-min,
.gym-grayscale-min {
  filter: grayscale(50%);
}

.gym-sepia {
  filter: sepia(75%);
}

.gym-sepia-max,
.gym-hover-sepia:hover {
  filter: sepia(100%);
}

.gym-sepia-min {
  filter: sepia(50%);
}

.gym-tiny {
  font-size: 10px !important;
}

.gym-small {
  font-size: 12px !important;
}

.gym-medium {
  font-size: 16px !important;
}

.gym-large {
  font-size: 18px !important;
}

.gym-xlarge {
  font-size: 24px !important;
}

.gym-xxlarge {
  font-size: 36px !important;
}

.gym-xxxlarge {
  font-size: 48px !important;
}

.gym-jumbo {
  font-size: 64px !important;
}

.gym-left-align {
  text-align: left !important;
}

.gym-right-align {
  text-align: right !important;
}

.gym-justify {
  text-align: justify !important;
}

.gym-center {
  text-align: center !important;
}

.gym-border-0 {
  border: 0 !important;
}

.gym-border {
  border: 1px solid #ccc !important;
}

.gym-border-top {
  border-top: 1px solid #ccc !important;
}

.gym-border-bottom {
  border-bottom: 1px solid #ccc !important;
}

.gym-border-left {
  border-left: 1px solid #ccc !important;
}

.gym-border-right {
  border-right: 1px solid #ccc !important;
}

.gym-topbar {
  border-top: 6px solid #ccc !important;
}

.gym-bottombar {
  border-bottom: 6px solid #ccc !important;
}

.gym-leftbar {
  border-left: 6px solid #ccc !important;
}

.gym-rightbar {
  border-right: 6px solid #ccc !important;
}

.gym-section,
.gym-code {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.gym-margin {
  margin: 16px !important;
}

.gym-margin-top {
  margin-top: 16px !important;
}

.gym-margin-bottom {
  margin-bottom: 16px !important;
}

.gym-margin-left {
  margin-left: 16px !important;
}

.gym-margin-right {
  margin-right: 16px !important;
}

.gym-padding-small {
  padding: 4px 8px !important;
}

.gym-padding {
  padding: 7px 16px !important;
}

.gym-padding-large {
  padding: 12px 24px !important;
}

.gym-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.gym-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.gym-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.gym-padding-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.gym-padding-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.gym-padding-top-64 {
  padding-top: 64px !important;
}

.gym-padding-top-48 {
  padding-top: 48px !important;
}

.gym-padding-top-32 {
  padding-top: 32px !important;
}

.gym-padding-top-24 {
  padding-top: 24px !important;
}

.gym-left {
  float: left !important;
}

.gym-right {
  float: right !important;
}

.gym-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}

.gym-transparent,
.gym-hover-none:hover {
  background-color: transparent !important;
}

.gym-hover-none:hover {
  box-shadow: none !important;
}

/* Colors */
.gym-amber,
.gym-hover-amber:hover {
  color: #000 !important;
  background-color: #ffc107 !important;
}

.gym-aqua,
.gym-hover-aqua:hover {
  color: #000 !important;
  background-color: #00ffff !important;
}

.gym-blue,
.gym-hover-blue:hover {
  color: #fff !important;
  background-color: #2196f3 !important;
}

.gym-light-blue,
.gym-hover-light-blue:hover {
  color: #000 !important;
  background-color: #87ceeb !important;
}

.gym-brown,
.gym-hover-brown:hover {
  color: #fff !important;
  background-color: #795548 !important;
}

.gym-cyan,
.gym-hover-cyan:hover {
  color: #000 !important;
  background-color: #00bcd4 !important;
}

.gym-blue-grey,
.gym-hover-blue-grey:hover,
.gym-blue-gray,
.gym-hover-blue-gray:hover {
  color: #fff !important;
  background-color: #607d8b !important;
}

.gym-green,
.gym-hover-green:hover {
  color: #fff !important;
  background-color: #4caf50 !important;
}

.gym-light-green,
.gym-hover-light-green:hover {
  color: #000 !important;
  background-color: #8bc34a !important;
}

.gym-indigo,
.gym-hover-indigo:hover {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.gym-khaki,
.gym-hover-khaki:hover {
  color: #000 !important;
  background-color: #f0e68c !important;
}

.gym-lime,
.gym-hover-lime:hover {
  color: #000 !important;
  background-color: #cddc39 !important;
}

.gym-orange,
.gym-hover-orange:hover {
  color: #000 !important;
  background-color: #ff9800 !important;
}

.gym-deep-orange,
.gym-hover-deep-orange:hover {
  color: #fff !important;
  background-color: #ff5722 !important;
}

.gym-pink,
.gym-hover-pink:hover {
  color: #fff !important;
  background-color: #e91e63 !important;
}

.gym-purple,
.gym-hover-purple:hover {
  color: #fff !important;
  background-color: #9c27b0 !important;
}

.gym-deep-purple,
.gym-hover-deep-purple:hover {
  color: #fff !important;
  background-color: #f1f7fe !important;
}

.gym-red,
.gym-hover-red:hover {
  color: #000 !important;
  background-color: #f1f7fe !important;
}

.gym-sand,
.gym-hover-sand:hover {
  color: #000 !important;
  background-color: #fdf5e6 !important;
}

.gym-teal,
.gym-hover-teal:hover {
  color: #fff !important;
  background-color: #009688 !important;
}

.gym-yellow,
.gym-hover-yellow:hover {
  color: #000 !important;
  background-color: #ffeb3b !important;
}

.gym-white,
.gym-hover-white:hover {
  color: #000 !important;
  background-color: transparent !important;
}

.gym-black,
.gym-hover-black:hover {
  color: #fff !important;
  background-color: #000 !important;
}

.gym-grey,
.gym-hover-grey:hover,
.gym-gray,
.gym-hover-gray:hover {
  color: #000 !important;
  background-color: #9e9e9e !important;
}

.gym-light-grey,
.gym-hover-light-grey:hover,
.gym-light-gray,
.gym-hover-light-gray:hover {
  color: #000 !important;
  background-color: #f1f1f1 !important;
}

.gym-dark-grey,
.gym-hover-dark-grey:hover,
.gym-dark-gray,
.gym-hover-dark-gray:hover {
  color: #fff !important;
  background-color: #616161 !important;
}

.gym-pale-red,
.gym-hover-pale-red:hover {
  color: #000 !important;
  background-color: #ffdddd !important;
}

.gym-pale-green,
.gym-hover-pale-green:hover {
  color: #000 !important;
  background-color: #ddffdd !important;
}

.gym-pale-yellow,
.gym-hover-pale-yellow:hover {
  color: #000 !important;
  background-color: #ffffcc !important;
}

.gym-pale-blue,
.gym-hover-pale-blue:hover {
  color: #000 !important;
  background-color: #ddffff !important;
}

.gym-text-amber,
.gym-hover-text-amber:hover {
  color: #ffc107 !important;
}

.gym-text-aqua,
.gym-hover-text-aqua:hover {
  color: #00ffff !important;
}

.gym-text-blue,
.gym-hover-text-blue:hover {
  color: #2196f3 !important;
}

.gym-text-light-blue,
.gym-hover-text-light-blue:hover {
  color: #87ceeb !important;
}

.gym-text-brown,
.gym-hover-text-brown:hover {
  color: #795548 !important;
}

.gym-text-cyan,
.gym-hover-text-cyan:hover {
  color: #00bcd4 !important;
}

.gym-text-blue-grey,
.gym-hover-text-blue-grey:hover,
.gym-text-blue-gray,
.gym-hover-text-blue-gray:hover {
  color: #607d8b !important;
}

.gym-text-green,
.gym-hover-text-green:hover {
  color: #4caf50 !important;
}

.gym-text-light-green,
.gym-hover-text-light-green:hover {
  color: #8bc34a !important;
}

.gym-text-indigo,
.gym-hover-text-indigo:hover {
  color: #3f51b5 !important;
}

.gym-text-khaki,
.gym-hover-text-khaki:hover {
  color: #b4aa50 !important;
}

.gym-text-lime,
.gym-hover-text-lime:hover {
  color: #cddc39 !important;
}

.gym-text-orange,
.gym-hover-text-orange:hover {
  color: #ff9800 !important;
}

.gym-text-deep-orange,
.gym-hover-text-deep-orange:hover {
  color: #ff5722 !important;
}

.gym-text-pink,
.gym-hover-text-pink:hover {
  color: #e91e63 !important;
}

.gym-text-purple,
.gym-hover-text-purple:hover {
  color: #9c27b0 !important;
}

.gym-text-deep-purple,
.gym-hover-text-deep-purple:hover {
  color: #673ab7 !important;
}

.gym-text-red,
.gym-hover-text-red:hover {
  color: #f44336 !important;
}

.gym-text-sand,
.gym-hover-text-sand:hover {
  color: #fdf5e6 !important;
}

.gym-text-teal,
.gym-hover-text-teal:hover {
  color: #009688 !important;
}

.gym-text-yellow,
.gym-hover-text-yellow:hover {
  color: #d2be0e !important;
}

.gym-text-white,
.gym-hover-text-white:hover {
  color: #fff !important;
}

.gym-text-black,
.gym-hover-text-black:hover {
  color: #000 !important;
}

.gym-text-grey,
.gym-hover-text-grey:hover,
.gym-text-gray,
.gym-hover-text-gray:hover {
  color: #757575 !important;
}

.gym-text-light-grey,
.gym-hover-text-light-grey:hover,
.gym-text-light-gray,
.gym-hover-text-light-gray:hover {
  color: #f1f1f1 !important;
}

.gym-text-dark-grey,
.gym-hover-text-dark-grey:hover,
.gym-text-dark-gray,
.gym-hover-text-dark-gray:hover {
  color: #3a3a3a !important;
}

.gym-border-amber,
.gym-hover-border-amber:hover {
  border-color: #ffc107 !important;
}

.gym-border-aqua,
.gym-hover-border-aqua:hover {
  border-color: #00ffff !important;
}

.gym-border-blue,
.gym-hover-border-blue:hover {
  border-color: #2196f3 !important;
}

.gym-border-light-blue,
.gym-hover-border-light-blue:hover {
  border-color: #87ceeb !important;
}

.gym-border-brown,
.gym-hover-border-brown:hover {
  border-color: #795548 !important;
}

.gym-border-cyan,
.gym-hover-border-cyan:hover {
  border-color: #00bcd4 !important;
}

.gym-border-blue-grey,
.gym-hover-border-blue-grey:hover,
.gym-border-blue-gray,
.gym-hover-border-blue-gray:hover {
  border-color: #607d8b !important;
}

.gym-border-green,
.gym-hover-border-green:hover {
  border-color: #4caf50 !important;
}

.gym-border-light-green,
.gym-hover-border-light-green:hover {
  border-color: #8bc34a !important;
}

.gym-border-indigo,
.gym-hover-border-indigo:hover {
  border-color: #3f51b5 !important;
}

.gym-border-khaki,
.gym-hover-border-khaki:hover {
  border-color: #f0e68c !important;
}

.gym-border-lime,
.gym-hover-border-lime:hover {
  border-color: #cddc39 !important;
}

.gym-border-orange,
.gym-hover-border-orange:hover {
  border-color: #ff9800 !important;
}

.gym-border-deep-orange,
.gym-hover-border-deep-orange:hover {
  border-color: #ff5722 !important;
}

.gym-border-pink,
.gym-hover-border-pink:hover {
  border-color: #e91e63 !important;
}

.gym-border-purple,
.gym-hover-border-purple:hover {
  border-color: #9c27b0 !important;
}

.gym-border-deep-purple,
.gym-hover-border-deep-purple:hover {
  border-color: #673ab7 !important;
}

.gym-border-red,
.gym-hover-border-red:hover {
  border-color: #f44336 !important;
}

.gym-border-sand,
.gym-hover-border-sand:hover {
  border-color: #fdf5e6 !important;
}

.gym-border-teal,
.gym-hover-border-teal:hover {
  border-color: #009688 !important;
}

.gym-border-yellow,
.gym-hover-border-yellow:hover {
  border-color: #ffeb3b !important;
}

.gym-border-white,
.gym-hover-border-white:hover {
  border-color: #fff !important;
}

.gym-border-black,
.gym-hover-border-black:hover {
  border-color: #000 !important;
}

.gym-border-grey,
.gym-hover-border-grey:hover,
.gym-border-gray,
.gym-hover-border-gray:hover {
  border-color: #9e9e9e !important;
}

.gym-border-light-grey,
.gym-hover-border-light-grey:hover,
.gym-border-light-gray,
.gym-hover-border-light-gray:hover {
  border-color: #f1f1f1 !important;
}

.gym-border-dark-grey,
.gym-hover-border-dark-grey:hover,
.gym-border-dark-gray,
.gym-hover-border-dark-gray:hover {
  border-color: #616161 !important;
}

.gym-border-pale-red,
.gym-hover-border-pale-red:hover {
  border-color: #ffe7e7 !important;
}

.gym-border-pale-green,
.gym-hover-border-pale-green:hover {
  border-color: #e7ffe7 !important;
}

.gym-border-pale-yellow,
.gym-hover-border-pale-yellow:hover {
  border-color: #ffffcc !important;
}

.gym-border-pale-blue,
.gym-hover-border-pale-blue:hover {
  border-color: #e7ffff !important;
}

.gym-bar-block a img {
  width: 20px;
  height: 20px;
  line-height: 30px;
  margin: 0px 5px 0px -30px;
}

.gym-white,
.gym-hover-white.active {
  color: #000 !important;
  background-color: #fff !important;
  margin-left: -15px;
  width: calc(100% + 15px);
  border-radius: 0px 15px 15px 0px;
  padding-left: 55px;
  box-shadow: 0px 1px 5px #6f64ab42;
  transition: all 1.5s ease-out;
}

.logout {
  position: absolute;
  bottom: 10px;
  width: inherit !important;
}

.searchicon {
  position: absolute;
  right: 20px;
  margin-top: -35px;
}

.searchicon2 {
  position: absolute;
  right: 123px;
  top: 12px;
}

.forminput {
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
  border-radius: 8px;
  padding: 6px 15px;
  height: 35px;
  background-color: #fff;
}

.mt0 {
  margin-top: 0px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.ml0 {
  margin-left: 0px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.mr0 {
  margin-right: 0px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.pt0 {
  padding-top: 0px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pb0 {
  padding-bottom: 0px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pl0 {
  padding-left: 0px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.pr0 {
  padding-right: 0px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

.pr30 {
  padding-right: 30px;
}

.button {
  color: #fff !important;
  background-color: #193b8b !important;
  border-radius: 5px;
}

.gym-half {
  padding: 0px 20px 0px 0px;
  position: relative;
}

.gym-table .header {
  background: #193b8b;
  color: #fff;
  padding: 5px;
}

.gym-table .body tr:nth-child(even) {
  background-color: #f6fdff;
}

.btn-trans {
  background-color: transparent !important;
  color: var(--mdb-link-color) !important;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ccc;
}

.login {
  background: url(../images/login-bg.png) no-repeat;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  text-align: center;
}

.login .gym-container {
  background-color: rgb(100 28 209 / 90%);
  height: 100vh;
  color: #fff;
}

.login form {
  margin: auto auto;
}

.btn-black {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 5px;
}

.btn:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.dblock {
  display: block;
}

.remainders-middle a {
  color: inherit;
}

a {
  color: #193b8b;
  text-decoration: none;
  padding: 0px 15px;
}

.insidecheck .innerdata {
  font-size: 12px;
  color: #777;
}

/* .insidecheck span:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
} */

.checkIn-Head {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 100%;
}

.gym-main .gym-container {
  padding: 0.01em 0px;
  margin-top: 0px;
}

/* Custom Scrollbar CSS Start*/

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f1f7fe;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: #f1f7fe;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #81b5f1;
}

/* Custom Scrollbar CSS End*/

.text-center {
  text-align: center;
}

input,
textarea,
select {
  outline: none;
}

.gym-main-pg {
  z-index: 3;
  width: 300px;
  display: none;
}

.gym-main-close {
  width: 100%;
  font-size: 22px;
}

.gym-main-w {
  width: 100%;
}

.gym-hide-close {
  cursor: pointer;
  display: none;
}

.gym-main-div {
  margin-left: 340px;
  margin-right: 20px;
}

.rightContent {
  margin-left: 340px;
  margin-right: 20px;
}

.gym-cnr {
  margin-top: 20px;
}

.gym-larg-pos {
  position: relative;
}

.gym-sidenav {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gym-tax {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  font-size: 12px;
}

.gym-gst {
  padding: 5px 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gym-payment-bord {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gym-addons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.gym-adons-rn {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
}

.gym-icons-d {
  position: absolute;
  bottom: 34px;
  right: 36px;
}

.gym-adon-position {
  position: relative;
  /* width: 90px;
    margin: 0 0 0 12px; */
}

.gym-trainer {
  margin: 30px 0 0 0;
  font-size: 20px;
}

.gym-sessions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.gym-span-session {
  margin: 15px 0 0 0;
  display: flex;
  align-items: center;
}

.gym-pay {
  width: 100%;
  text-align: center;
}

/* --------------------------New Code--------------------------------- */
body,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
}

body {
  font-size: 16px;
}

.gym-half img {
  margin-bottom: -6px;
  margin-top: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.gym-half img:hover {
  opacity: 1;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.w-70 {
  width: 70% !important;
}

.w-30 {
  width: 30% !important;
}

.payment-board {
  /* padding: 10px 30px; */
  /* margin: 30px 0 0 30px; */
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: normal;
  padding: 10px;
}

.payment-board.gym-payment-bord {
  justify-content: space-between !important;
}

.img-cart {
  background-color: white;
  height: 90px;
  width: 90px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-bag {
  margin: 20px 0 0 10px;
  display: flex;
  align-items: center;
}

.inpt-search {
  width: 100%;
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  height: 50px;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.user-prev-details {
  position: relative;
}

.user-prev-details label {
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0px;
  position: absolute;
  top: 0;
  width: 20px;
}

.user-prev-details label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 10px;
}

.user-prev-details input[type="checkbox"] {
  visibility: hidden;
}

.user-prev-details input[type="checkbox"]:checked+label {
  background-color: #50c878;
}

.user-prev-details input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.round-gym-home {
  position: relative;
}

.round-gym-home label {
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0px;
  position: absolute;
  top: 0;
  width: 20px;
}

.round-gym-home label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 10px;
}

.round-gym-home input[type="checkbox"] {
  visibility: hidden;
}

.round-gym-home input[type="checkbox"]:checked+label {
  background-color: #000;
}

.round-gym-home input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.gym-half img {
  margin-bottom: -6px;
  margin-top: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.gym-half img:hover {
  opacity: 1;
}

.user-modal {
  display: none;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.user-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: 40%;
}

.user-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.user-close:hover,
.user-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.p-0 {
  padding: 0px;
}

.edit-user-p {
  display: flex;
  justify-content: space-between;
}

.p-3 {
  padding: 3px;
}

.clr-user {
  color: #8e8e8e;
}

.gym-half img {
  margin-bottom: -6px;
  margin-top: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.gym-half img:hover {
  opacity: 1;
}

.gym-half img {
  margin-bottom: -6px;
  margin-top: 16px;
  opacity: 0.8;
  cursor: pointer;
}

.gym-half img:hover {
  opacity: 1;
}

.gym-modal {
  display: none;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.gym-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.gym-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.gym-close:hover,
.gym-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.user-pdng {
  padding: 0px;
}

.justify-center {
  justify-content: center;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.payment-board {
  /* padding: 10px 30px; */
  /* margin: 20px 0 0 14px; */
  background-color: #f6f6f6;
}

.img-cart {
  background-color: white;
  height: 90px;
  width: 90px;
  border-radius: 10px;
}

.user-modal {
  display: none;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.user-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

.user-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.user-close:hover,
.user-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.clr-white {
  color: white;
}

.d-in-block {
  display: inline-block;
}

.business-form {
  /* display: -webkit-inline-box; */
  flex-flow: wrap;
}

.clr-red {
  color: red;
  /* padding-top: 5px; */
  margin: 0px;
}

@media (max-width: 1700px) and (min-width: 900px) {

  .waveForm {
    width: 60% !important;
  }

  .gym-bar-block .gym-bar-item {
    width: 100%;
    display: block;
    padding: 8px 10px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
    padding-left: 40px;
    line-height: 24px;
  }

  .gym-white,
  .gym-hover-white.active {
    color: #000 !important;
    background-color: #fff !important;
    margin-left: -15px;
    width: calc(100% + 15px);
    border-radius: 0px 15px 15px 0px;
    padding-left: 55px;
    box-shadow: 0px 1px 5px #6f64ab42;
    transition: all 1.5s ease-out;
  }

  .card-h {
    height: 140px !important;
  }
}

.kep-login-facebook {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #3b71ca !important;
  border: 0px !important;
  padding: 2px !important;
}

.kep-login-facebook:hover {
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 12px !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #ffffff !important;
}

#GsigninDiv iframe {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  height: 44px;
  width: 100% !important;
  border: 0px 0px;
  margin: -2px -10px;
}

/*----------------------------- New code Here Bostrap --------------------------------*/
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.remainder-email {
  padding: 10px !important;
  text-align: center !important;
  font-size: 14px !important;
  border-right: 1px solid #eee !important;
  color: #7e7e7e !important;
  background-color: #fbfbfb !important;
  margin-top: 10px !important;
}

.gym-bar-itemstextp {
  text-align: end;
}

.table-flow {
  max-height: 450px;
  overflow-y: scroll;
}

.checkin-bar-fixed {
  border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid darkgrey;
  padding: 6px;
}

.btn-cancel {
  color: #000;
  background-color: #d3d3d3;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  margin-right: 10px;
  padding: 7px;
}

.img-ft-4 {
  position: absolute;
  bottom: 0px;
  left: -71px;
  width: 250px;
}

.img-ft-3 {
  position: absolute;
  bottom: 0px;
  left: -20px;
  width: 240px;
}

.img-ft-2 {
  position: absolute;
  bottom: 0px;
  left: -65px;
  width: 250px;
}

.img-ft {
  position: absolute;
  bottom: 0px;
  left: -85px;
  width: 250px;
}

.w-15 {
  width: 15px;
}

.clr-main {
  color: #193b8b;
}

.card-gym {
  height: 150px;
  border: 1px solid #e6e6e6 !important;
  margin-top: 20px;
  overflow: hidden;
}

.w-180 {
  width: 180px;
}

.w-200 {
  width: 200px;
}

.w-220 {
  width: 220px;
}

.w-250 {
  width: 250px;
}

.w-280 {
  width: 280px;
}

.w-300 {
  width: 300px;
}

.w-180 {
  width: 180px;
}

.searchicon-dashboard {
  position: absolute;
  right: 14px;
  margin-top: -28px;
}

.h-40 {
  height: 40px;
  font-size: 14px;
}

.h-44 {
  height: 44px;
  font-size: 14px;
}

.mr-15 {
  margin-right: 15px;
}

.dashboard-center {
  align-items: baseline;
}

.clr-selected {
  color: #193b8b;
  border: 1px solid #193b8b;
}

.bg-clr-select {
  background-color: #d7d7d7;
}

.margingym {
  margin-top: revert !important;
  margin-bottom: inherit !important;
}

.marginbottomgym {
  margin-bottom: inherit !important;
}

.gym-date {
  /* width: 132px; */
  margin: 5px 3px 0px 0;
  border: 0px solid #dbdbdb;
  border-radius: 2px;
  color: #b1b1b1;
  font-size: 12px;
  height: 25px;
  cursor: pointer;
}

.checkindate {
  display: flex;
  justify-content: end;
}

/* Popup style */
.popup-boxsub {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
}

.boxsub {
  position: relative;
  width: 30%;
  margin: 0 auto;
  height: 27vh;
  max-height: 124vh;
  margin-top: calc(100vh - 68vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #999;
  overflow: auto;
}

.close-iconsub {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(36% - 19px);
  top: calc(100vh - 70vh - 2px);
  width: 25px;
  height: 25px;
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  margin: 20px 0 0px 0px;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
}

.box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: auto;
  max-height: 80vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(13% - 19px);
  top: calc(100vh - 89vh - 2px);

  width: 25px;
  height: 25px;

  line-height: 20px;
  text-align: center;
  font-size: 20px;
  margin: 20px 0 0px 0px;
}

.button-green {
  background-color: #30d500 !important;
  color: white !important;
}

.font-weight {
  font-weight: bold;
}

.searchList {
  padding: 5px 10px 5px 10px;
  background-color: #f9f9f9;
  border-radius: 2px;
}

.searchListactive {
  padding: 5px 10px 5px 10px;
  background-color: #ececec;
  border-radius: 2px;
}

.searchList:hover {
  padding: 5px 10px 5px 10px;
  background-color: #ececec;
  border-radius: 2px;
}

.imgCurrency {
  width: 10px;
  margin: 0 6px 0 0;
}

.inp-clrdate {
  width: 85px;
  font-size: 12px;
  height: 22px;
  border: 1px solid #656565;
  border-radius: 3px;
  padding: 3px;
  color: #656565;
  text-align: center;
}

.inp-clrdate-Small {
  width: 85px !important;
  font-size: 14px;
  height: 26px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 3px;
  color: #656565;
  text-align: left;
  margin-left: 10px;
}


.inp-clrdate-Small1 {
  width: 160px;
  font-size: 14px;
  height: 26px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 3px;
  color: #656565;
  text-align: left;
}

.inp-clrdate-Small2 {
  width: auto;
  font-size: 14px;
  height: 26px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 3px;
  color: #656565;
  text-align: left;
}

.mt-20 {
  margin-top: 20px;
}

.addonamount {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.payment-inp {
  width: 95px;
  border: 1px solid #adadad;
  border-radius: 3px;
  color: grey;
}

.payment-inp-small {
  width: 59px;
  border: 1px solid #adadad;
  border-radius: 3px;
  color: grey;
  margin: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.align-center {
  align-items: center;
}

.w-10 {
  width: 10px;
}

.printpayment {
  width: 100%;
  margin: auto;
}

.bold {
  font-weight: bold;
}

.w-30 {
  width: 30%;
}

.line-height {
  line-height: 1.6;
}

.text-ends {
  text-align: end;
}

.login-new {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 70%;
}

.w-20vw {
  width: 20vw;
}

.h-100vh {
  height: 100vh;
}

.h-100vhWave {
  /* height: 100vh; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  padding: 25px 0;
}

.waveForm {
  width: 40%;
}

.home-select-drp {
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 5px 15px;
}

.home-select-drp:hover {
  justify-content: space-between;
  background-color: #cdcdcd !important;
  padding: 5px 15px;
}

.home-select-drp:active {
  justify-content: space-between;
  background-color: #ababab;
  padding: 5px 15px;
}

.home-active {
  justify-content: space-between;
  background-color: #fff;
  padding: 5px 15px;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 5px 15px;
}

.home-select-drp:hover {
  justify-content: space-between;
  background-color: #f5f5f5 !important;
  padding: 5px 15px;
}

.home-select-drp:active {
  justify-content: space-between;
  background-color: #ababab;
  padding: 5px 15px;
}

.home-active {
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 5px 15px;
}

.printeralignemnt {
  margin: 10px;
  display: flex;
  justify-content: left;
}

.close-icon-m {
  cursor: pointer;
  position: fixed;
  left: calc(100% - 130px);
  top: calc(100vh - 100vh - 2px);
  width: 25px;
  height: 25px;
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  margin: 20px 0 0px 0px;
}

#snackbar-submit {
  visibility: hidden;
  font-size: 17px;
  /* position: absolute; */
  z-index: 999;
  top: 25px;
}

.justify-content-cente {
  justify-content: center;
}

#snackbar-submit.show-submit {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.borderheight {
  height: 91px;
  display: inline-block;
  padding: 0px;
}

.dynamicscrollmain {
  overflow: auto;
  max-height: calc(100vh - 310px);
}

.dynscrolll {
  overflow: auto;
  max-height: calc(100vh - 571px);
}

.otherpaymentbox {
  border: 1px solid #ccc;
  padding: 0 20px 0px 20px;
}

.amountdisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balanceamount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 0px 0;
}

.p-10 {
  padding: 10px;
}

/* CSS Document */

@keyframes MoveUpDown {
  0% {
    top: 0px;
  }

  50% {
    top: 30px;
  }

  100% {
    top: 0px;
  }
}

@keyframes down {}

.dis {
  display: inline-block;
}

.scrollContent tr:nth-child(even) {
  background: #f6fdff;
}

.mainfont {
  font-size: 36px;
  font-family: myFirstFont;
}

@font-face {
  font-family: myFirstFont;
}

table,
td,
a {
  color: #000;
  font: normal normal 12px Verdana, Geneva, Arial, Helvetica, sans-serif;
}

.tableContainer {
  overflow-y: auto;
  overflow-x: auto;
  width: calc(100% + 0px);
  margin: 0px;
  /* height: calc(100vh - 100px) !important; */
}



/* .tableContainer {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
} */

.fixedHeader tr {
  position: relative;
}

.fixedHeader th {
  /*border-right: 1px solid #dbdbdb;
            /*border-right: 1px solid #dbdbdb;
            /*border-top: 1px solid #EB8;*/
  font-weight: normal;
  padding: 8px 8px;
  text-align: left;
  background: #193b8b;
  font-weight: bold;
  color: #fff;
}

.scrollContent {
  display: block;
  max-height: 380px;
  overflow: auto;
  width: 100%;
}

.fixedHeader {
  display: table;
  overflow: auto;
  width: 100%;
}

.scrollContent td,
.scrollContent .normalRow td {
  /*background: #FFF;*/
  border-bottom: none;
  border-left: none;
  /*border-right: 1px solid #CCC;
            border-top: 1px solid #DDD;*/
  padding: 8px 8px;
}

.scrollContent .alternateRow td {
  background: #eee;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ddd;
  padding: 2px 3px 3px 4px;
}

.tablewidth {
  width: 169px;
  min-width: 169px;
}

.w_100per {
  width: 100%;
}

.loginbgimage {
  max-height: 100vh;
  height: 100vh;
}

.slot-img {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.slot-img span {
  padding-right: 5px;
}

.slot-datep {
  /* padding: 5px;
  color: #000;
  border: 1px solid #dbdbdb;
  width: 95px;
  background-color: #f0f0f0;
  margin: 5px;
   */
  padding: 5px;
  border: 2px solid #dbdbdb;
  margin: 0 10px 5px 0;
  width: 95px;
  box-shadow: inset #dbdbdb3d 0px 2px 6px 0px;
  border-radius: 5px;
}

.slot-datepactive {
  /* padding: 5px;
  color: #ffffff;
  border: 1px solid #dbdbdb;
  width: 95px;
  background-color: #50c878;
  margin: 0 5px 0 0px;
  border-radius: 3px; */


  padding: 5px;
  border: 2px solid #50c878;
  margin: 0 10px 5px 0;
  width: 95px;
  box-shadow: inset #50c87860 0px 2px 6px 0px;
  border-radius: 5px;
}

.allowedslot-box {
  display: flex;
  align-items: center;
}

.slot-foot {
  display: flex;
  width: 100%;
}

.allowedslotsmain-box {
  background: #f2f2f2;
  padding: 5px;
  border: 2px solid #dbdbdb;
  margin: 0 10px 5px 0;
  width: 175px;
  border-radius: 5px;

}

.allowedslotsmain-boxactive {
  /* background: #50c878; */
  /* color: #ffffff; */
  padding: 5px;
  border: 2px solid #50c878;
  margin: 0 10px 5px 0;
  width: 175px;
  box-shadow: inset #50c87860 0px 2px 6px 0px;
  border-radius: 5px;
}

.allowedslot-box .slottime span:first-child {
  display: block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.allowedslot-box .slottime span:nth-child(2) {}

.w-120 {
  padding: 5px 10px !important;
  font-size: 10px !important;
}

.font-15 {
  font-size: 15px;
}

.view-checkin {
  font-size: 12px;
  margin: 0px 13px;
}

.align-center {
  align-items: center;
}

.P-10 {
  padding: 10px !important;
}

.font-12 {
  font-size: 12px;
}

.close-icon-viewpop {
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  margin: 20px 0 0px 0px;
}

.P-0 {
  padding: 0px;
}

.w-16 {
  width: 14px;
}

.view-boxdate {
  /* background-color: #f2f2f2; */
  padding: 7px 8px;
  color: #333;
  border-radius: 3px;
  margin: 0 5px;
  border: 1px solid #ccc;
  font-size: 12px;
}

.view-name {
  font-size: 1.4rem !important;
  font-weight: bold;
}

.pb-10 {
  padding-bottom: 10px;
}

.log-time-card {
  text-align: center;
  padding: 5px 10px;
  color: #777;
}

.log-time-card span {
  color: #777;
}

.mt-25 {
  margin-top: 25px;
}

.pl-35 {
  padding-left: 35px;
}

.log-expire {
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}

.checkindate~span.pt-1.text-end {
  background: #fff;
  /* margin: 3px 0 2px 0; */
  padding: 0.4em;
  cursor: pointer;
  width: max-content;
  border: 1px solid #d7dde8;
  vertical-align: middle;
}

.w-120px {
  width: 120px !important;
}

.w-50px {
  width: 50px !important;
}

.h-17 {
  height: 15px;
  margin-left: 8px;
}

.text-end {
  text-align: end;
}

.ft-10 {
  font-size: 10px;
}

.h-103 {
  height: 75px;
  position: absolute;
  right: 10px;
  bottom: 0;
}

.p-15 {
  padding: 7px 10px !important;
}

.card-body-dashb {
  flex: 1 1 auto;
  padding: 0rem 0.9rem !important;
}


.clr-main a {
  color: #193b8b !important;
  font-weight: bold;
  font-size: 20px;
}

.clr-red a {
  color: red;
}

.clr-dashb-red a {
  color: red;
  font-weight: bold;
  font-size: 20px;
}

.dashboard-text {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.dashboard-text-over {
  color: #4b4b4b;
  font-size: 16px;
  padding-left: 30px;
}

.clr-main-green a {
  color: #50c818;
  font-size: 20px;
  font-weight: bold;
}

.clr-main-green a:hover {
  color: #50c818;
  font-size: 20px;
  font-weight: bold;
}

.clr-main-red a:hover {
  color: red;
  font-size: 20px;
  font-weight: bold;
}

.clr-main-red a {
  color: red;
  font-size: 20px;
  font-weight: bold;
}

.paymt-rec {
  height: 30px;
  width: 30px;
}

.w-48 {
  width: 48% !important;
}

.d-flex-dashboard {
  display: flex;
}

.f-9 {
  font-size: 10px;
}

.add-numbr-clr {
  color: #3b3b3b;
  font-family: poppins;
}

.clr-black {
  color: #3b3b3b;
  font-family: poppins;
}

.pt-10 {
  padding-top: 10px;
}

.add-mbrs-span {
  font-weight: bold !important;
  padding: 2px 5px 2px 0px;
}

.mt-12 {
  margin-top: 12px;
}

.modal-footer {
  width: 100%;
}

.modal {
  transition: opacity 0.55s linear;
  background: #00000070;
}

.datatable {
  --datatable-color: #212529;
  --datatable-border-color: #e0e0e0;
  --datatable-striped-color: #212529;
  --datatable-accent-bg: rgba(0, 0, 0, 0.02);
  --datatable-hover-color: #131313;
  --datatable-hover-bg: rgba(0, 0, 0, 0.048);
  --datatable-muted-color: grey;
  --datatable-active-color: rgba(19, 19, 19, 0.05);
  font-size: 0.9rem;
  color: var(--datatable-color);
  background-color: #fff;
}

.datatable-inner,
.datatable table {
  background-color: inherit;
}

.datatable table {
  color: var(--datatable-color);
  margin-bottom: 0;
  border-color: var(--datatable-border-color);
  border-width: 1px;
  border-style: solid;
}

.datatable table td,
.datatable table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  padding: 1rem 1.4rem;
}

.datatable thead,
.datatable thead tr {
  background-color: inherit;
}

.datatable thead tr {
  border-bottom: 1px solid var(--datatable-border-color);
}

.datatable thead th {
  position: relative;
  border-bottom: none;
  font-weight: 500;
}

.datatable thead th:hover .datatable-sort-icon {
  opacity: 1;
}

.datatable thead .fixed-cell {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: var(--datatable-border-color) 0 1px;
  background-color: #e7eef6;
  text-transform: capitalize;
}

.datatable tbody {
  font-weight: 300;
  background-color: inherit;
  margin-top: 3px;
}

.datatable tbody .fixed-cell {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background-color: inherit;
}

.datatable tbody tr {
  background-color: inherit;
  transition: all 0.3s ease-in;
}

.cursorPointer tbody tr {
  cursor: pointer;
}

.datatable tbody tr:nth-child(even) {
  background: #f4fcff;
}


.datatable tbody tr:focus {
  outline: none;
}

.datatable tbody tr.active {
  background-color: var(--datatable-active-color);
}

.datatable tbody td:focus {
  outline: none;
}

.datatable-loader {
  width: 100%;
  height: 2px;
  position: relative;
  overflow: hidden;
}

.datatable-loader-inner,
.datatable-progress {
  display: block;
  height: 100%;
}

.datatable-progress {
  -webkit-animation: datatableProgress 3s ease-in-out;
  animation: datatableProgress 3s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  width: 45%;
  position: relative;
  opacity: 0.5;
  border-radius: 1px;
}

@-webkit-keyframes datatableProgress {
  0% {
    left: -45%;
  }

  to {
    left: 100%;
  }
}

@keyframes datatableProgress {
  0% {
    left: -45%;
  }

  to {
    left: 100%;
  }
}

.datatable-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0;
  border-top: 1px solid var(--datatable-border-color);

  background-color: white;
  border-top: 1px solid #e0e0e0 !important;
  padding: 0.5rem !important;
}

.datatable-pagination-nav {
  padding: 0;
  font-size: 0.9rem;
  direction: ltr;
  /*!rtl:ignore*/
}

.datatable-pagination-buttons {
  margin-left: 2rem;
}

.datatable-pagination-button {
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--datatable-color);
}

.datatable-sort-icon {
  opacity: 0;
  color: var(--datatable-muted-color);
  cursor: pointer;
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  position: absolute;
  right: 0.4rem;
  top: calc(50% - 0.5rem);
}

.datatable-sort-icon.active {
  opacity: 1;
  color: var(--datatable-color);
}

.datatable-select-wrapper {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 300;
  padding: 0 1rem;
}

.datatable-select-wrapper .select-input {
  max-width: 70px;
}

.datatable-select-text {
  margin: 0 1rem;
}

.datatable.datatable-sm td,
.datatable.datatable-sm th {
  padding: 0.5rem 1.4rem;
}

.datatable.datatable-sm tbody tr:last-child {
  border-bottom: transparent;
  height: 55px;
}

.datatable.datatable-sm .datatable-pagination {
  padding: 0.2rem 0;
}

.datatable.datatable-bordered td,
.datatable.datatable-bordered th {
  border-width: 1px;
}

.datatable.datatable-bordered .datatable-pagination {
  border: 1px solid var(--datatable-border-color);
}

.datatable.datatable-striped tbody tr:nth-of-type(odd) {
  background-color: var(--datatable-accent-bg);
  color: var(--datatable-striped-color);
}

.datatable.datatable-hover tbody tr {
  transition: background-color 0.2s ease-in !important;
}

.datatable.datatable-hover tbody tr:hover {
  background-color: var(--datatable-hover-bg) !important;
}

.datatable.datatable-clickable-rows tbody tr {
  cursor: pointer;
}

.datatable.datatable-clickable-rows tbody tr.active {
  background-color: var(--datatable-hover-bg);
}

.datatable.datatable-dark {
  --datatable-color: #fff;
  --datatable-border-color: #fff;
  --datatable-active-color: hsla(0, 0%, 100%, 0.2);
}

.datatable.datatable-dark .select-wrapper .select-arrow,
.datatable.datatable-dark .select-wrapper input {
  color: #fff;
}

.datatable.datatable-dark .select-wrapper .form-notch-leading,
.datatable.datatable-dark .select-wrapper .form-notch-trailing,
.datatable.datatable-dark .select-wrapper .form-noth-middle,
.datatable.datatable-dark .select-wrapper .form-outline .form-control:focus~.form-notch .form-notch-leading {
  border-color: #fbfbfb !important;
}

.datatable.datatable-dark .form-check-input[type="checkbox"]:checked {
  background-color: transparent;
  border-color: #fff;
}

.datatable.datatable-dark .datatable-progress {
  opacity: 0.8;
}

.datatable.datatable-dark.datatable-striped {
  --datatable-striped-color: #fff;
  --datatable-accent-bg: hsla(0, 0%, 100%, 0.05);
}

.datatable.datatable-dark.datatable-hover {
  --datatable-hover-bg: hsla(0, 0%, 100%, 0.2);
  --datatable-hover-color: #fff;
}

.datatable.border-primary {
  --datatable-border-color: #3b71ca;
}

.datatable.border-secondary {
  --datatable-border-color: #9fa6b2;
}

.datatable.border-success {
  --datatable-border-color: #14a44d;
}

.datatable.border-danger {
  --datatable-border-color: #dc4c64;
}

.datatable.border-warning {
  --datatable-border-color: #e4a11b;
}

.datatable.border-info {
  --datatable-border-color: #54b4d3;
}

.datatable.border-light {
  --datatable-border-color: #fbfbfb;
}

.datatable.border-dark {
  --datatable-border-color: #332d2d;
}

.datatable.datatable-borderless {
  --datatable-border-color: transparent;
}

.datatable.datatable-loading,
.datatable.datatable-loading th {
  color: var(--datatable-muted-color);
}

.datatable.datatable-loading .datatable-sort-icon {
  display: none;
}

@media (max-width: 520px) {
  .datatable-pagination {
    flex-direction: column-reverse;
  }

  .datatable-pagination-nav {
    margin: 0.8rem 0;
  }

  .datatable-pagination-button {
    font-size: 1rem;
  }

  .datatable-pagination-buttons {
    margin: 0.8rem 0;
  }
}

.datatable thead th {
  position: relative;
  border-bottom: none;
  font-weight: bold !important;
}

.table thead th {
  font-weight: bold !important;
}

.datatable table td,
.datatable table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  padding: 0.25rem !important;
  font-size: 11px;
  vertical-align: middle;
  border-right: 1px solid #eee;
}

.datatable thead .fixed-cell {
  position: sticky;
  top: 0;
  z-index: 3;
  box-shadow: var(--datatable-border-color) 0 1px;
  background-color: #e7eef6 !important;
}

.pl-4 {
  padding-left: 4px;
}

/* -------------------------Table Pagination ----------------------------------------*/
.select-dropdown {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: 0;
  min-width: 100px;
  outline: 0;
  position: relative;
  transform: scaleY(0.8);
  opacity: 0;
  transition: all 0.2s;
}

.select-dropdown.open {
  transform: scaleY(1);
  opacity: 1;
}

.select-options-wrapper {
  overflow-y: auto;
}

.select-options-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select-option.active,
.select-option.selected.active,
.select-option:hover:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.048);
}

.select-option {
  width: 100%;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  min-height: 38px !important;
  height: auto !important;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; */
}


.form-check-input {
  position: relative;
  width: 1.125rem !important;
  height: 1.125rem !important;
  background-color: #fff;
  border: 0.125rem solid rgba(0, 0, 0, .25);
}

@media (max-width: 520px) {
  .datatable-pagination-nav {
    margin: 0.8rem 0;
  }
}

.datatable-pagination-nav {
  padding: 0;
  font-size: 0.9rem;
  direction: ltr
    /*!rtl:ignore*/
  ;
}

@media (max-width: 520px) {
  .datatable-pagination-buttons {
    margin: 0.8rem 0;
  }

  .usersearch {
    position: relative !important;
    width: 100% !important;
  }
}

.btn-link.disabled,
.btn-link:disabled,
fieldset:disabled .btn-link {
  box-shadow: none;
  color: #3b71ca;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  box-shadow: 0 4px 9px -4px rgb(0 0 0 / 35%);
  border: 0;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

@media (max-width: 520px) {
  .datatable-pagination-button {
    font-size: 1rem;
  }
}

.datatable-pagination-button {
  padding-left: 1rem;
  padding-right: 1rem;
  color: var(--datatable-color);
}

.btn-link {
  box-shadow: none;
  text-decoration: none;
  color: #3b71ca;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa,
.fa-brands,
.fa-duotone,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fab,
.fad,
.fal,
.far,
.fas,
.fat {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.dashb-compare {
  font-size: 13px;
  padding-left: 10px;
}

.align-center {
  align-items: center;
}


.font-15 {
  font-size: 15px !important;
}

.bg-clr-red {
  background-color: #a73636 !important;
}

.bg-clr-grey {
  background-color: #676767 !important;
}

.sign-up-br {
  border: 1px solid #cdcdcd !important;
}

.sign-up-w {
  width: 60% !important;
}

.card-h {
  height: 160px !important;
  padding: 10px;
}

@media (min-width: 2200px) and (max-width: 4000px) {
  .box {
    position: relative;
    width: 30%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #999;
    overflow: auto;
  }

  .close-icon {
    content: "x";
    cursor: pointer;
    position: fixed;
    right: calc(39% - 92px);
    top: calc(100vh - 86vh - 23px);
  }
}

@media (min-width: 1980px) and (max-width: 4000px) {
  .tablewidth {
    width: 273px;
    min-width: 273px;
  }

  .usersearch {
    position: fixed !important;
  }
}

@media (min-width: 1080px) and (max-width: 1920px) {
  .searchicon {
    position: absolute;
    right: 16px;
    margin-top: -29px;
  }

  .usersearch {
    position: fixed !important;
  }

  .gym-xlarge {
    font-size: 15px !important;
  }

  .gym-large {
    font-size: 15px !important;
  }

  .tablewidth {
    width: 193px;
    min-width: 193px;
  }

  .boxsub {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 68vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #999;
    overflow: auto;
  }

  .close-icon {
    right: calc(29% - 80px);
    top: calc(100vh - 89vh - 2px);
  }

  .box {
    position: relative;
    width: 56%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #999;
    overflow: auto;
  }

  .close-iconsub {
    content: "x";
    cursor: pointer;
    position: fixed;
    right: calc(22% - 19px);
    top: calc(100vh - 73vh - 2px);
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    margin: 20px 0 0px 0px;
  }

  .img-cart {
    background-color: white;
    height: 70px;
    width: 70px;
    border-radius: 10px;
    font-size: 12px;
  }

  .payment-board {
    /* padding: 10px 0px 10px 10px; */
    margin: 0px -30px 0px 0px;
    background-color: #f6f6f6;
    height: calc(100vh - 95px);
  }


  #user-myBtn~.col-lg-3 {

    padding: 10px 0px 10px 10px !important;
  }

  .printpayment {
    width: 100% !important;
    margin: auto;
  }

  .paymt-rec {
    /* height: 20px; */
    width: 20px;
  }

  .clr-main-green a {
    color: #50c818;
    font-weight: bold;
  }

  .h-103 {
    height: 75px;
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  .clr-main-red a {
    color: red;
    font-weight: bold;
  }

  .clr-dashb-red a {
    color: red;
    font-weight: bold;
  }

  .card-title {
    margin-bottom: var(--mdb-card-title-spacer-y);
    font-size: 12px;
  }

}

@media (min-width: 1080px) and (max-width: 1520px) {
  .modal-footer .btn {
    /* width: 80px; */
    padding: 7px;
  }
}

@media (min-width: 800px) and (max-width: 1700px) {
  .col-lp-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
}

@media (max-width: 992px) {
  .gym-main-pg {
    z-index: 3;
    width: 100vw;
    display: none;
    padding-top: 10vh;
  }

  .waveForm {
    width: 80% !important;
  }

  .gym-sidebar {
    padding: 30px 0 0 0;
  }

  .gym-main-w {
    width: 75vw;
    padding: 10px 30px;
  }
}

@media (min-width: 620px) and (max-width: 1028px) {
  .splitpayment {
    width: 100%;
    padding: 0 3px 0px 0;
    padding-bottom: 20px;
  }

  .waveForm {
    width: 90% !important;
  }

  .duepaymentmain-payments {
    border: 1px solid #dbdbdb;
    /* width: 30%; */
    padding: 20px;
    /* display: flex; */
    justify-content: center;
    display: list-item;
  }

  .splitpaymentright {
    padding: 0 0 0 5px;
  }
}

@media (min-width: 620px) and (max-width: 768px) {
  .createplan {
    margin: 0 5px 0px 5px;
  }

  .w-90 {
    width: 95%;
    margin: auto !important;
  }
}

@media (min-width: 480px) and (max-width: 920px) {
  .gym-bar-itemstext {
    text-align: left !important;
  }

  .gym-bar-itemstextp {
    text-align: left !important;
  }

  .ml20 {
    margin-left: 0px;
  }

  .gym-xlarge {
    font-size: 16px !important;
  }

  .gym-large {
    font-size: 15px !important;
  }

  .payment-board {
    /* padding: 10px 30px; */
    /* margin: 10px 0 0 0px; */
    background-color: #f6f6f6;
  }
}

@media (min-width: 320px) and (max-width: 997px) {
  .gym-cnr {
    margin-top: 85px !important;
  }

  .page-loader {
    position: fixed;
  }

  .gym-red,
  .gym-hover-red:hover {
    color: #000 !important;
    background-color: #f1f7fe !important;
    border-right: 1px solid #8fa5bf;
  }

  .splitpayment {
    width: 100%;
    padding: 0 3px 0px 0;
    padding-bottom: 20px;
  }

  .duepaymentmain-payments {
    border: 1px solid #dbdbdb;
    /* width: 30%; */
    padding: 5px;
    /* display: flex; */
    justify-content: center;
    display: list-item;
  }

  .splitpaymentright {
    padding: 0 0 0 5px;
  }

  .w-sm-80 {
    width: 100% !important;
    padding: 0px;
  }

  .w-220 {
    width: auto !important;
  }

  .mobileResloution .gym-section {
    width: 50%;
    padding: 0px 5px;
  }

  .mobileResloution .gym-section.order-0 {
    width: 100%;
    padding: 0px 5px;
  }

  .mobileResloution .gym-section .fw-bold {
    font-size: 14px !important;
  }

  .mobileResloution .gym-section.order-5 {
    width: 100%;
  }

  .gym-input.forminput.h-44.w-220 {
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .d-inline.m-0.me-2 {
    font-size: 11px;
  }

  .searchbar-result {
    width: 100% !important;
  }

  .gym-tax-data {
    position: fixed !important;
    width: 100%;
    bottom: 50px !important;
    padding: 0 10px;
    right: 0px;
    z-index: 9;
  }

  .searchbar-cart {
    padding: 0 5px;
  }

  .gym-payment-bord {
    height: auto !important;
  }

  .timegym-home.daterow .d-flex.flex-end.row {
    padding: 0 20px;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .login-new {
    display: inline;
    justify-content: center;
    align-items: center;
  }

  .sign-up-w {
    width: 100% !important;
  }

  .w-20vw {
    width: 92vw !important;
  }

  .loginbgimage {
    display: none;
  }

  .sign-up-w {
    width: 100% !important;
    margin: 0 2%;
  }

  .login-new {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }


  .dashboard_links a {
    cursor: pointer;
    font-size: 14px;
    color: #000 !important;
    margin: 5px !important;
    text-align: center;
  }

  .dashboard_links .active {
    color: #386bc0 !important;
    background: #f5f5f5;
    padding: 10px !important;
    margin: 5px !important;
    border-radius: 15px;
  }

  .gym-margin-right {
    margin-right: 10px !important;
  }

  .h-103 {
    height: 40px !important;
  }

  .card-h {
    height: auto !important;
    padding: 10px;
  }

  .card-gym {
    height: auto !important;
    border: 1px solid #e6e6e6 !important;
    margin-top: 20px;
  }

  .col-6 {
    padding: 0 0 !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

}

@media (min-width: 320px) and (max-width: 470px) {

  /*--------------------------- New code here 28-02-23 ----------------------------------*/
  .w-70 {
    width: 40px;
  }


  .w-sm-100 {
    width: 100% !important;
  }

  .card-gym {
    margin: 10px 5px !important;
  }

  .card-title {
    margin-bottom: var(--mdb-card-title-spacer-y);
    font-size: 15px !important;
  }

  .clr-main a {
    color: #193b8b;
    font-weight: bold;
    font-size: 15px !important;
  }

  /* .h-103 {
    height: 60px !important;
  } */

  .small,
  small {
    font-size: 12px !important;
  }

  .paymt-rec {
    height: 20px !important;
    width: 20px !important;
  }

  .clr-main-green a {
    color: #50c818;
    font-size: 15px !important;
    font-weight: bold;
  }

  .clr-main-red a {
    color: red;
    font-size: 15px !important;
    font-weight: bold;
  }

  .clr-dashb-red a {
    color: red;
    font-weight: bold;
    font-size: 15px !important;
  }

  .nav-link {
    font-size: 11px !important;
    padding: 15px 10px !important;
  }

  .w-140 {
    width: 198px !important;
  }

  .gym-large {
    font-size: 14px !important;
  }

  .forminput {
    font-size: 12px !important;
  }

  .home-active {
    background-color: #eeeeee !important;
  }

  .searchicon {
    position: absolute;
    right: 9px;
    margin-top: -22px;
    height: 14px;
  }

  .timegym-home {
    padding: 10px 5px;
  }

  .gym-payment-bord {
    height: auto !important;
  }

  .gym-adons-rn {
    cursor: pointer;
    margin-top: 10px;
    margin-left: 0px !important;
  }

  .img-cart {
    background-color: white;
    height: 70px;
    width: 70px;
    border-radius: 10px;
  }

  .h5,
  h5 {
    font-size: 15px;
  }

  .gym-trainer {
    margin: 30px 0 0 0;
    font-size: 15px;
  }

  .usercar-prev {
    box-shadow: 0px 1px 5px #ddd;
    padding: 10px !important;
  }

  .mainboxsecond {
    display: block !important;
  }

  .dynscrolll {
    overflow: auto;
    max-height: 75vh;
  }

  .mob-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mob-flex button {
    width: 100%;
    margin-top: 10px;
  }

  .mob-mt-4 {
    margin-top: 1.5rem !important;
  }

  .BusinessPage__content .card-body,
  .Business__Details__data a {
    padding: 0 !important;
  }

  .Business__Details__data {
    display: block !important;
  }

  .h5,
  h5 {
    font-size: 14px;
  }

  .paymentsuccess {
    font-size: 16px !important;
    font-weight: bold;
    margin: 8px 0 0 0;
  }

  .ppaymentsuccess-numbr {
    font-size: 20px !important;
    font-weight: bold;
    margin: 3px 0 0 0;
  }

  .paymentsuccess-plan {
    font-size: 20px;
    font-weight: bold;
    margin: 3px 0 0 0;
  }

  .d-content {
    display: contents !important;
  }

  /*--------------------------- New code here 28-02-23 end ----------------------------------*/

  .searchicon-dashboard {
    position: absolute !important;
    right: 2px !important;
    margin-top: -26px !important;
    height: 15px !important;
    width: 15px !important;
  }

  .h-40 {
    height: 40px;
    font-size: 12px !important;
  }

  .pl-35 {
    padding-left: 8px !important;
  }

  .slot-foot {
    display: contents !important;
    width: 100%;
  }

  .w-20vw {
    width: 92vw !important;
  }

  .loginbgimage {
    max-height: 27vh !important;
    height: 27vh;
  }

  .d-fl {
    display: flex;
  }

  .h-100vh {
    height: 30vh !important;
  }

  .h-100vhWave {
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    padding: 0;
  }

  .page-loader {
    position: fixed !important;
  }


  .waveForm {
    width: 100% !important;
  }

  .waveForm {
    position: absolute;
    top: 0px;
  }

  .gym-bar-itemstextp {
    text-align: left;
  }

  .close-icon {
    content: "x";
    cursor: pointer;
    position: fixed;
    right: calc(13% - 19px);
    top: calc(100vh - 89vh - 2px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    margin: 20px 0 0px 0px;
  }

  .gym-bar-itemstext {
    text-align: left;
  }

  .table-scroll {
    overflow: scroll;
    height: 100vh;
  }

  .gym-cnr {
    margin-top: 55px !important;
  }

  .gym-main {
    width: 95%;
    margin: auto !important;
  }

  .splitpayment {
    width: 100%;
    padding: 0 3px 0px 0;
    padding-bottom: 20px;
  }

  .duepaymentmain-payments {
    border: 1px solid #dbdbdb;
    /* width: 30%; */
    padding: 20px;
    /* display: flex; */
    justify-content: center;
    display: list-item;
  }

  .splitpaymentright {
    padding: 0 0 0 5px;
  }

  .usercar-prev {
    width: 100%;
  }

  .gym-main-w {
    width: 50vw !important;
    padding: 10px 10px !important;
  }

  .gym-display-topleft {
    position: relative;
    left: 0;
    top: 0;
  }

  .gym-main-close {
    width: 10%;
    font-size: 22px;
  }

  .gym-main-pg {
    padding-top: 1px !important;
  }

  .close-menu-mobile {
    display: flex;
    justify-content: space-between;
  }

  .gym-sidebar {
    padding-top: 10px !important;
  }

  .gym-padding-24 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .ml20 {
    margin-left: 1px;
  }

  .btn-trans {
    padding: 6px 5px;
  }

  .edit-user-p {
    align-items: center;
  }

  .slotname {
    display: inherit;
  }

  .w-90 {
    width: 95%;
    margin: auto !important;
  }

  .w-90_percent {
    width: 90% !important;
  }

  .createplan {
    border: 0px solid #ccc;
    box-shadow: 0px 1px 5px #ddd;
    display: flex;
    /* padding: 4px; */
    border-radius: 3px;
    cursor: pointer;
    margin: 0 0px 10px 0px;
    font-size: 12px;
    align-items: center;
    text-align: center;
    height: auto;
    width: max-content;
  }

  .addic {
    margin: 0 0px 0 0;
  }

  .gym-cnr {
    margin-top: 55px !important;
  }

  .slotmain {
    width: 95%;
    margin: auto !important;
  }

  .w-16 {
    width: 16px;
  }

  .createplanmain {
    display: block;
  }

  .mobile-d-flex {
    display: flex;
  }

  .mob-show {
    display: block !important;
  }

  .p-1 {
    padding: 2px;
  }

  .pl-5 {
    padding-left: 5px;
  }

  .gym-main {
    width: 99%;
    margin: auto !important;
    margin-top: 50px !important;
  }

  .table-scroll {
    overflow: scroll;
    height: 100vh;
  }

  .duepaymentmain {
    border: 1px solid #dbdbdb;
    padding: 20px;
    justify-content: center;
    margin-top: 95px;
    width: 100%;
  }

  .user-modal-content {
    width: 90%;
  }

  .gym-large {
    font-size: 16px !important;
  }

  .gym-padding {
    padding: 3px 2px !important;
  }



  .gym-margin-right {
    margin-right: 1px !important;
  }

  .slotdrops {
    margin-left: 10px;
  }

  .slotpopups {
    width: fit-content;
    padding: 20px 0px 0px 0px;
  }

  .gym-section,
  .gym-code {
    margin-top: 4px !important;
    margin-bottom: 8px !important;
  }

  .mt30 {
    margin-top: 5px;
  }

  .gym-section h4 {
    font-size: 14px;
  }

  .gym-section label {
    font-size: 14px;
  }

  .gym-main .gym-container {
    padding: 5px;
  }

  .payment-board {
    /* padding: 11px 20px; */
    /* margin: 0px 0 0 0px; */
    background-color: #f6f6f6;
  }

  .gym-payment-bord {
    height: calc(75vh - 135px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .slotselect {
    width: 195px;
  }
}

@media (max-width: 360px) {
  .gym-cnr {
    margin-top: 70px;
  }

  .w-140 {
    width: 192px !important;
  }

  .btn-mobile-rsp {
    margin: 12px 0px !important;
    display: flex !important;
    justify-content: flex-end !important;
  }

  .d-content {
    display: contents !important;
  }

  .m-0 {
    margin: 0px;
  }

  .createplan {
    border: 0px solid #ccc;
    box-shadow: 0px 1px 5px #ddd;
    display: flex;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 0px 10px 0px;
    font-size: 13px;
    align-items: center;
    text-align: center;
    height: 47px;
  }

  .slotmain {
    width: 95%;
    margin: auto !important;
  }

  .timegym {
    border: 0px solid #ccc;
    box-shadow: 0px 1px 5px #ddd;
    padding: 10px 7px;
    margin: 30px 0 0 0;
    display: flex;
    align-items: center;
    font-size: 13px;
    justify-content: space-between;
  }

  .gym-payment-bord {
    height: calc(83vh - 135px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .payment-board {
    /* padding: 11px 20px; */
    /* margin: 250px 0 0 0px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f6f6f6;
    padding: 10px;
  }

  .createplan {
    height: 47px;
  }

  .gym-right {
    float: left !important;
    margin-top: 10px;
  }

  .slotname {
    display: block;
  }

  .slotname label {
    display: contents;
  }

  .w-90 {
    width: 100%;
    margin: auto !important;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.splitinputwallet {
  width: 100px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #d7d7d7;
  margin-right: 10px;
}

.splitinput {
  width: 85%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #d7d7d7;
}

.splitinputupi {
  width: 138px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #d7d7d7;
  margin-right: 10px;
}

.justify-left {
  justify-content: left !important;
}

.coupen-code {
  width: 110px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  font-size: 13px;
  border-bottom: 2px solid #d7d7d7;
}

.w-11 {
  width: 11px;
  margin: 5px;
}

.paymt-clr-Multiple {
  background-color: #8ea8bb;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-1 {
  background-color: #00b1d5;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-2 {
  background-color: #9d5dff;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}


.phonepecolor {
  background-color: #5f239d !important;
}

.paymt-clr-3 {
  background-color: #ffcc67;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-4 {
  background-color: #62e962;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-5 {
  background-color: #ff6a6a;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-6 {
  background-color: #8ea8bb;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-7 {
  background-color: #5dc2ff;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-8 {
  background-color: #ff67dc;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-9 {
  background-color: #3bc4ba;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-10 {
  background-color: #6a7eff;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-11 {
  background-color: #e52e95;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.paymt-clr-12 {
  background-color: #667bb2;
  color: white;
  padding: 20px;
  width: 23%;
  text-align: center;
  border-radius: 5px;
  margin: 10px 6px 10px 0px;
}

.m-5-6 {
  margin: 5px 6px;
}

.align-self-end {
  align-items: self-end;
  display: flex !important;
}

.align-self-start {
  align-self: self-start;
  display: flex !important;
  padding: 13px 0 0 0;
}

.w-18 {
  width: 14px;
}

.settings-coupen {
  width: 138px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #d7d7d7;
  margin: 10px 20px 10px 6px;
}

.justify-start {
  justify-content: flex-start !important;
}

.payment-span {
  font-size: 14px;
  padding-left: 8px;
  color: #191919;
}

.w-27 {
  width: 37px;
}

.react-datepicker-wrapper {
  display: block !important;
  padding: 0;
  border: 0;
}

.google-email div {
  min-width: none !important;
}

.w-100 {
  width: 100%;
}

.ml-2 {
  margin-left: 5px;
}

.g-mail {
  border-right: 1px solid #b9b9b9 !important;
  width: 20%;
}

.logn-sign-btn {
  margin-left: 15px;
}

.navbar {
  background: #fff;
}

.navbar .navbar-brand .logo-light-mode {
  height: 50px !important;
  width: auto !important;
}

.navbar .navbar-brand .logo-dark-mode {
  height: 50px !important;
  width: auto !important;
}

.mx-auto {
  /*margin-top: 129px;*/
}

.gymlogo1 {
  width: 320px;
  padding-left: 20px;
  margin-bottom: 25px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.loginstyle {
  display: flex;
  align-items: center;
}

.greenoverlay {
  /* position: absolute; */
  width: 100vw;
  height: 100vh;
  background: #50c87830;
}

.prisingdiv {
  height: 100% !important;
}

.pricingh {
  height: 80px;
}

.section {
  padding: 20px 0px !important;
}

.button-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.closegym a {
  color: #fff;
  /* font-weight: bold; */
  /* font-size: 16px; */
}

.popup-bussiness {
  display: contents;
}

.font {
  font-size: 12px;
}

.section,
#features,
#pricing,
#contact {
  padding: 5rem 1.5rem !important;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {

  .small,
  small {
    font-size: 50% !important;
  }

  .back-to-home .icons {
    transform: scale(4) !important;
  }

  .usersearch {
    position: fixed !important;
  }
}

@media screen and (max-width: 1599.99px) {
  .price.h3 {
    font-size: 24px !important;
    line-height: 1.65;
  }

  .pricingh .text-muted img {
    width: 20px !important;
  }

  .price.h3 small {
    font-size: 50% !important;
  }

  .PoweredTxt {
    /* position: relative !important; */
    bottom: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }

  .prisingdiv .p-4 {
    padding: 1.125rem !important;
  }

  .planlist li {
    font-size: 14px !important;
  }

  .Addon-Highlight .addOn-Details {
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .Addon-Highlight .addOn-Details span {
    padding: 5px 0;
  }

  .Addon-Highlight .addOn-Details div.d-flex {
    padding: 5px 0;
    align-items: center;
  }

  .user-prev-details {
    display: flex;
    align-items: center;
  }
}

.btn {
  padding: 6px 15px !important;
  font-size: 12px !important;
  height: auto !important;
}

.btn-padding {
  margin-top: 15px;
  display: inline-block;
}

.mx-autos {
  width: 950px;
}

.slotred {
  position: inherit !important;
}

.expiry {
  font-size: 12px;
}

.card-text small {
  font-size: 85% !important;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .pricingPlan {
    padding: 0 0.15rem !important;
  }

  .price.h3 small {
    font-size: 60% !important;
  }

  .price.h3 {
    font-size: 16px !important;
    line-height: 1.8;
  }

  .pricingh {
    height: 60px;
  }

  .rupee {
    padding-top: 5px;
  }

  .pricingDiv {
    width: 100%;
  }

  .pricingDiv .pricingPlan {
    width: calc(100% - 80%);
  }

  .prisingdiv .p-4 {
    padding: 1.125rem !important;
  }

  .usersearch.dropdown {
    position: absolute !important;
  }

  .PoweredTxt {
    position: relative !important;
    bottom: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
  }
}

.accountName {
  text-transform: uppercase;
  display: block;
  font-size: 14px;
  line-height: normal;
  /* border: 1px solid #3b71ca; */
  border-radius: 5px;
  padding: 8px 0px;
  background: #56c97a;
}

.accountName small {
  text-align: right;
  font-size: 70% !important;
  text-transform: capitalize;
}

.dash-icon {
  font-size: 1.25rem;
  color: #fff;
  display: inline-block;
}

.accountName a {
  padding: 0 10px;
  color: #fff;
}


.accountName a:hover {
  color: #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 1px solid !important;
}


.dashboard_date_picker .react-datepicker__input-container,
.react-datepicker-wrapper {
  width: auto !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.font-14px {
  font-size: 14px !important;
}

.searchIcon-position {
  position: absolute;
  bottom: 13px;
  right: 30px;
}

.input-select {
  background-color: transparent;
  padding: 0 0 3px 0;
}

.usersearch {
  position: fixed !important;
  width: 225px;
  min-height: 40px;
  max-height: 150px;
  z-index: 9999;
  overflow-y: auto;
}

@media (max-width: 767.9px) {
  .gym-modal-content {
    width: 500px;
  }

  .gym-modal {
    padding-top: 50px;
  }

  .usersearch {
    position: relative !important;
    width: 125px;
    min-height: 40px;
    max-height: 150px;
    z-index: 9999;
    overflow-y: auto;
  }
}


.dashboard_links .customRange {
  display: flex !important;
}

.paymt-clr-Multiple .w-25,
.paymt-clr-1 .w-25,
.paymt-clr-2 .w-25,
.paymt-clr-3 .w-25,
.paymt-clr-4 .w-25,
.paymt-clr-5 .w-25,
.paymt-clr-6 .w-25,
.paymt-clr-7 .w-25,
.paymt-clr-8 .w-25,
.paymt-clr-9 .w-25,
.paymt-clr-10 .w-25,
.paymt-clr-11 .w-25,
.paymt-clr-12 .w-25 {
  display: block;
  width: 50% !important;
  margin: 0 auto;
  min-height: 70% !important;
}

.settings-icon {
  width: 20px;
}

.form-check-input.form-check-input:checked {
  background-color: transparent !important;
  border-color: #50c878;
}

.form-check-input[type=radio]:checked:after {
  border-color: #50c878 !important;
  background-color: #50c878 !important;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1em !important;
}

.overflow_inh {
  overflow: inherit !important;
}



/* Toggle Css */

/* .togglelabel {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggleinput {
  opacity: 0;
  width: 0;
  height: 0;
}

.togglespan {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #cfcfcf;
  transition: 0.3s;
  border-radius: 30px;
}

span:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 2.6px;
  background-color: #424242;
  border-radius: 50%;
  transition: 0.3s;
}

.toggleinput:checked + .togglespan {
  background-color: #50c878;
}

.toggleinput:checked + .togglespan:before {
  transform: translateX(29px);
} */


.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
  margin: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.form-check-input[type=checkbox]:checked:after,
input[type=checkbox]:checked:after {
  width: 0.35rem !important;
  height: 0.7125rem !important;
  margin-left: 0.3rem !important;
  margin-top: 0px !important;
  background-color: #50c878 !important;
}

.form-check-input[type="checkbox"]:checked,
.form-check-input[type="radio"]:checked:after {
  background-image: none;
  background-color: #50c878 !important;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #50c878;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.m-0 {
  margin: 0 !important;
}

.tgggle-adjust {
  display: flex;
  flex-wrap: wrap;
}

.toggle-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}


.justify-between {
  justify-content: space-between;
}

.text-customize {
  text-transform: capitalize;
  font-size: 14px;
}

.showLeft {
  background-color: white !important;
  border: 1px solid white !important;
  text-shadow: white !important;
  color: black !important;
  padding: 10px;
}

.icons li {
  background: none repeat scroll 0 0 black;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}

.btn-left {
  left: 0.4em;
}

.btn-right {
  right: 0.4em;
}

.btn-left,
.btn-right {
  position: absolute;
  top: 0.24em;
}

.dropbtn {
  background-color: #4caf50;
  position: fixed;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

.dropdown {
  position: absolute;
  display: inline-block;
  right: 0.4em;
}

.dropdown-content {
  display: none;
  position: relative;
  margin-top: 60px;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

/* three dot menu */

.showLeft {
  background-color: white !important;
  border: 1px solid white !important;
  text-shadow: white !important;
  color: black !important;
  padding: 10px;
}

.icons li {
  background: none repeat scroll 0 0 black;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}

.btn-left {
  left: 0.4em;
}

.btn-right {
  right: 0.4em;
}

.btn-left,
.btn-right {
  position: absolute;
  top: 0.24em;
}

.dropbtn {
  background-color: #4caf50;
  position: fixed;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

.dropdown {
  position: absolute;
  display: inline-block;
  right: 0.4em;
}

.dropdown-content {
  display: none;
  position: relative;
  margin-top: 60px;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.editicons {
  position: absolute;
  top: 11px;
  right: 8px;
  background: #fff;
  width: 56px;
  display: none !important;
}

.cursorpointer {
  cursor: pointer;
}

.divhover {
  display: inline-block;
}

.divhover :hover .editicons {
  display: block !important;
}


.gym-tax-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px;
  font-size: 12px;
}


.gym-tax-data {
  position: absolute;
  width: 100%;
  bottom: 0px;

}

.bg-f6f6f6 {
  background-color: #efefef;
}

.fw-600 {
  font-weight: 600 !important;
}

.tax-border-top {
  border-top: 1px solid #131313;
}

.justify-content-start {
  justify-content: flex-start;
}

.modal-body-overflow {
  /* overflow: inherit; */
  /* overflow-x: hidden; */
}


#user-myBtn {
  padding-left: 0px;
  padding-right: 0;
}

.searchbar-result {
  position: absolute;
  width: calc(100vw - 340px);
  background-color: #ffffff;
  z-index: 10;
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
}

.searchbar-result .home-select-drp {

  background-color: #ffffff;

}

.searchbar-result .home-select-drp.home-active {

  background-color: #f5f5f5;

}

.pointonsale-widgets {
  width: 100%;
  max-height: calc(100vh - 345px);
  overflow-y: auto;
  /* box-shadow: 0px 1px 5px #ddd; */
}

.w-120px {
  width: 120px;
}

.modal-content .modal-body .rightContent {
  margin: 0px !important;
}

.paymentpopup h5.line-height {
  font-size: 14px !important;
}

.text-right {
  float: right;
}

.paymentpopup .checkinbuttonmain {
  margin: 10px 0 0 0;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.settings-icon {
  width: 24px;
  height: 24px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-option {
  cursor: pointer;
  margin-bottom: 8px;
}

.dropdown-option:hover {
  background-color: #e0e0e0;
}

.editicons {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #fff;
  width: 56px;
  display: none !important;
}

.cursorpointer {
  cursor: pointer;
}

.divhover {
  display: inline-block;
}

.divhover :hover .editicons {
  display: block !important;
}

.gym-tax-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px;
  font-size: 12px;
}

.gym-tax-data {
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding-top: 10px;
  background: #fff;
  border-top: 1px solid #eaeaea;
}

.bg-f6f6f6 {
  background-color: #efefef;
}

.fw-600 {
  font-weight: 600 !important;
}

.tax-border-top {
  border-top: 1px solid #131313;
}

.justify-content-start {
  justify-content: flex-start;
}

.popupppp {
  overflow: inherit;
}


#user-myBtn {
  padding-left: 0px;
}

.searchbar-result {
  position: absolute;
  width: calc(100vw - 340px);
  background-color: #ffffff;
  z-index: 10;
  border: 0px solid #ccc;
  box-shadow: 0px 1px 5px #ddd;
}


.searchbar-result .home-select-drp {

  background-color: #ffffff;

}

.searchbar-result .home-select-drp.home-active {

  background-color: #f5f5f5;

}

.pointonsale-widgets {
  width: 100%;
  max-height: calc(100vh - 293px);
  min-height: calc(100vh - 293px);
  overflow-y: auto;
  height: 100% !important;
  /* box-shadow: 0px 1px 5px #ddd; */
}

.point_sale_addOn {
  max-height: calc(100vh - 291px);
  min-height: calc(100vh - 291px);
  overflow: auto;
  height: 100% !important;
  padding-bottom: 3em;
}

.tab-content>.tab-pane {
  /* height: calc(100vh - 170px); */
}

.paymentcheckin {
  /* height: 200px; */
  overflow: auto;
  padding-bottom: 25px;
}

.float-right {
  float: right !important;
}

.logoImg {
  max-width: 180px;
  max-height: 80px;
}


.billContent .header {
  background-color: #ecfaff;
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  color: #193b8b;
}

.billContent .logo {
  width: 200px;
  height: auto;
}

.billContent .header-address {
  flex: 1;
  text-align: right;
  font-weight: 500;
}

.billContent .header-address * {
  margin: 0;
  line-height: 1.3;
}

.billContent .content {
  padding: 20px;
}

.billContent .primary-color {
  color: #193b8b;
}

.billContent .primary-bg {
  background: #50c878;
  text-align: center;
  color: #fff;
  padding: 10px;
}

.billContent .secondary-color {
  color: #50c878;
}

.billContent .details-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 14px;
}

.billContent .details-table th,
.billContent .details-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 14px !important;
}

.billContent .details-table th {
  text-align: left;

}

.billContent .details-table td:last-child {
  text-align: right;
}

.billContent .total-section tr td {
  padding: 0 1em;
  font-size: 14px;
}

.billContent .total-section {
  width: 100%;
  background: #e5eaeb;
  color: #193b8b;
}

.billContent .total-section td {
  padding: 0 20px;
}

.billContent .details-table .right,
.billContent .right {
  text-align: right;
}

.billContent .lightbg {
  background: #ecfaff;
}

.billContent .w100 {
  width: 100%;
}

.billContent .lightbg td,
.billContent .lightbg th,
.billContent .tablerow td {
  padding: 0 20px;
  font-size: 14px;
}

.billContent .tablerow td {
  background: #fff;
}

.billContent .table th,
.billContent .table td {
  border: 1px solid #d8eef5;
  font-size: 14px;
}

.billContent {
  font-family: Arial, sans-serif;
  padding: 0;
  margin: 0em;
  font-size: 14px !important;
  background-color: #ecfaff;
}

.billContent h6,
.billContent h5 {
  padding: 10px 0 !important;
  margin-bottom: 0px !important;
}

.billContent .logoImg {
  width: 10%;
  height: auto;
}

@media print {

  .billContent .header {
    background-color: #ecfaff;
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    color: #193b8b;
    font-size: 14px !important;
  }

  .billContent .logo {
    width: 200px;
    height: auto;
  }

  .billContent .header-address {
    flex: 1;
    text-align: right;
    font-weight: 500;
  }

  .billContent .header-address * {
    margin: 0;
    line-height: 1.3;
  }

  .billContent .content {
    padding: 20px;
  }

  .billContent .primary-color {
    color: #193b8b;
  }

  .billContent .primary-bg {
    background: #50c878;
    text-align: center;
    color: #fff;
    padding: 10px;
  }

  .billContent .secondary-color {
    color: #50c878;
  }

  .billContent .details-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .billContent .details-table th,
  .billContent .details-table td {
    padding: 0px 5px !important;
    border-bottom: 1px solid #ddd;
    font-size: 14px !important;
  }

  .billContent .details-table th {
    text-align: left;

  }

  .billContent .details-table td:last-child {
    text-align: right;
  }

  .billContent .total-section tr td {
    padding: 0 1em;
    font-size: 14px;
    color: #202020 !important;
  }

  .billContent .total-section {
    width: 100%;
    background: #e5eaeb;
    color: #193b8b;
  }

  .billContent .total-section td {
    padding: 0 20px;
  }

  .billContent .details-table .right,
  .billContent .right {
    text-align: right;
  }

  .billContent .lightbg {
    background: #ecfaff;
  }

  .billContent .w100 {
    width: 100%;
  }

  .billContent .lightbg td,
  .billContent .lightbg th,
  .billContent .tablerow td {
    padding: 0 20px;
    font-size: 14px;
  }

  .billContent .tablerow td {
    background: #fff;
    color: #202020 !important;
  }

  .billContent .table th,
  .billContent .table td {
    border: 1px solid #d8eef5;
    font-size: 14px;
  }

  .billContent {
    font-family: Arial, sans-serif;
    padding: 0;
    margin: 0em;
    font-size: 14px !important;
    background-color: #ecfaff;
  }

  .billContent h6,
  .billContent h5 {
    padding: 10px 0 !important;
    margin-bottom: 0px !important;
  }

  .billContent .logoImg {
    width: 10%;
    height: auto;
  }

  .billContent .logo {
    width: 300px;
    height: auto;
  }

  .billContent * {
    color: #202020 !important;
    font-size: 12px !important;
  }

  .billContent table tr th,
  .billContent table tr td {
    padding: 0px 5px !important;
    height: 15px !important;
    color: #202020 !important;
    font-size: 12px !important;
  }

  .billContent table tr th h6,
  .billContent table tr td h6 {
    padding: 3px 5px !important;
    color: #202020 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .billContent>table>.lightbg {
    background: #eee !important;
  }
}

.card-h .card-title {
  margin-bottom: 0px;
}

.card-body-dashb .card-text a {
  padding: 0px;
}

.user-info {
  position: static;
  height: auto;
}

.cover-user .container-fluid .padding-less.img {
  /* background: scroll center no-repeat; */
  background-size: cover !important;
  position: inherit !important;
  height: inherit !important;
}

@media (max-width: 1025px) and (min-width:769px) {
  .margin__signuo {
    margin: 10px 60px !important;
  }
}

@media (min-width: 1025px) and (max-width:3769px) {
  .margin__signuo {
    margin: 0px !important;
  }
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.left-0 {
  left: 0;
}

.print_ricipt_table {
  border: var(--mdb-modal-header-border-width) solid var(--mdb-modal-header-border-color);
  padding: 8px;
}

.border__rnone {
  border-right: none;
}

.clr-green {
  color: #37ae5f !important;
}

.clr-orange {
  color: #e4a11b !important;
}

.link-color {
  cursor: pointer !important;
  color: #3b71ca !important;
}

.timegymslot>a {
  padding: 0px 5px !important;
}

.z-index_4 {
  z-index: 4;
}

.w-40 {
  width: 40% !important;
}

.Transactionusers {
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tbody,
td,
tfoot,
tr {
  border: 1px solid !important;
  border-color: #ececec !important;
}

/* .btnheight {
  height: -webkit-fill-available !important;
} */

.modal-footer {
  padding: 10px 0px 10px 10px !important;
}

.Transaction__table .datatable-pagination {
  display: none;
}

.Transaction__table.datatable .scrollbar-container.datatable-inner {
  min-height: auto !important;
}

/* .modal-body .modal-body {
  padding: 0 !important;
} */

.daterow .row>* {
  position: unset !important;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.height_37 {
  height: 37px !important;
}

.modal-footer {
  padding: 10px 0 0 0 !important;
}

.w-60 {
  width: 60% !important;
}

.w-25 {
  width: 25% !important;
}

.h-34 {
  height: 34px !important;
}

.date__padding {
  padding: 5px 10px !important;
}

.p-20 {
  padding: 20px !important;
}

.fs-small {
  font-size: 0.75em;
}

.modal .modal-body p {
  font-size: 13px !important;
}

.modal {
  backdrop-filter: saturate(100%) blur(7px) !important;
}

.fs-12 {
  font-size: 12px;

}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.Business__Details__data {
  padding: 5px 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.business_Info_page .card-title,
.BusinessPage__content .card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.business_Info_page .mt-22px {
  margin-top: 22.5px;
}

.business_Info_page .card-title i {
  cursor: pointer;
}

.business_Info_page .payments ul li {
  padding: 0 0 0 0;
}

.business_Info_page .select-wrapper {
  width: 80px !important;
}


.BusinessPage__content .Business__input__adjust,
.BusinessPage__content select {
  width: 230px !important;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

.custom_Select select {
  width: 30px !important;

}

.BusinessPage__contentNew {
  width: 230px !important;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
  text-align: left;
}

.business_Info_page .modal-body .card-body {
  padding: 0 0 !important;
}

.payment_methods_input {
  width: 140px !important;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
}


.dashboard__CheckBoxes .form-check-label {
  font-weight: 600 !important;
}

.dashboard_links a {
  cursor: pointer;
  font-size: 14px;
  /* font-weight: unset; */
}

.dashboard_links .active {
  color: #193b8b !important;
}

.dashboard_note {
  font-size: smaller;
}

.dashboard_links a {
  cursor: pointer;
  font-size: 14px;
  color: #000 !important;

}

.dashboard_links {
  height: 45px;
}

.dashboard_links .active {
  color: #386bc0 !important;
}

.dashboard_note {
  font-size: smaller;
}

.w-200px {
  width: 200px;
}

.w-110px {
  width: 110px !important;
}

input[type="time"],
.time__input {
  width: auto;
  padding: 5px 0 5px 3px;
  text-align: center !important;
  width: 80px !important;
}

.seconds {
  position: absolute;
  bottom: -3px;
  right: 8px;
  color: #afafaf;
}

.business_hours {
  padding: 1rem 0rem !important;
}

.business_hours select {
  width: 80px !important;
}

.loadingclass {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  font-size: xx-large;
  justify-content: center;
  /* z-index: 100; */
  /* background: rgba(0, 0, 0, 0.149); */
  /* opacity: 1; */
}

.loading12 {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExpiredCheckin {
  background: #dbb400;
  border: 1px solid #dbb400;
  padding: 8px 15px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  width: 81.23px;
}

.logaToTime {
  margin-left: 100px;
}

.logaTimeDetails {
  font-size: 14px;
}

.modal-header {
  padding: 1rem 1.5rem !important;
}

.txt_wrapper {
  text-wrap: nowrap;
  position: relative;
  top: 40px;
  left: 73px;
}

.sms-message {
  word-break: break-all !important;
  word-wrap: break-word !important;
  white-space: pre !important;
  white-space: pre-wrap !important;
}

.sms-settings-table .datatable table td,
.sms-settings-table .datatable table th {
  padding: 1rem !important;
}

.sms-settings-table .datatable tr th:nth-child(1),
.sms-settings-table .datatable tr td:nth-child(1) {
  min-width: 150px;
}

.sms-settings-table .datatable tr th:nth-child(2),
.sms-settings-table .datatable tr td:nth-child(2) {
  width: 80%;
}

.sms-settings-info i {
  color: #505050;
  cursor: pointer;
  /* display: none; */
}

.sms-settings-info i:hover {
  color: var(--mdb-primary) !important;
}

.membership-notification .form-outline .form-control.form-control-lg {
  line-height: 2.5 !important;
  width: 93%;
  border: none !important;
}

.membership-notification .form-outline i {
  position: absolute !important;
  top: 17px;
  right: 10px;
  color: #505050;
  cursor: pointer !important;
  z-index: 99;
}

.membership-notification .form-outline i:hover {
  color: var(--mdb-primary) !important;
}

.smsgateway .form-outline,
.select-wrapper .form-outline {
  /* width: auto !important; */
}

.w-20 {
  width: 21% !important;
}


.sms-gatewaytable .datatable table td,
.sms-gatewaytable .datatable table th {
  padding: 1rem !important;
  border: none !important;
}

.sms-gatewaytable .datatable tr th:nth-child(1),
.sms-gatewaytable .datatable tr td:nth-child(1) {
  width: 50px;
}

.sms-gatewaytable .datatable tr th:nth-child(2),
.sms-gatewaytable .datatable tr td:nth-child(2),
.sms-gatewaytable .datatable tr td:nth-child(3),
.sms-gatewaytable .datatable tr td:nth-child(3) {
  width: 15%;
}

.sms-gatewaytable .datatable tr th:nth-child(4),
.sms-gatewaytable .datatable tr td:nth-child(4) {
  width: 40%;
}

.sms-gatewaytable .datatable tr th:nth-child(5),
.sms-gatewaytable .datatable tr td:nth-child(5) {
  width: 20%;
}

.sms-gatewayedit i {
  cursor: pointer !important;
}

.smsHistory .form-outline {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.form-outline>.form-control.mb-4 {
  width: 85% !important;
}

.sms-settings-table>div>.form-outline {
  max-width: 85% !important;
}

.right-25px {
  right: 25px !important;
}


.sms-history .datatable table td,
.sms-history .datatable table th,
.sms-history .datatable table td {

  padding: 0.8rem !important;
}

.datatable-body tr td button {
  font-size: 10px !important;
  text-transform: capital !important;
  cursor: pointer;
  /* padding: 5px !important; */
}

.modal-body {
  padding: 1.5rem 2rem !important;
}

.mt-4 .input-group {
  display: flex !important;
  align-items: center !important;
}

.toolpit-position .form-outline {
  position: relative;
  width: 100%;
  display: flex;
}

.toolpit-position .form-control {
  box-shadow: none !important;
}

.seconds {
  position: absolute;
  bottom: -3px;
  right: 8px;
  color: #afafaf;
}

.fs-12px {
  font-size: 12px !important;
}

/* 
.timepicker-head {
  background-color: #50c878 !important;
}

.timePicker-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timePicker-custom input {
  display: flex;
  align-items: center;
  margin: 0px !important;
  justify-content: center;
}

.timePicker-custom .form-outline .form-control~.form-label {
  top: -3px !important;
}

.w-47 {
  width: 47% !important;
}

.timepicker-head {
  background-color: #dbdbdb !important;
  color: #000 !important;
}

.timepicker-head *,
.timepicker-head * i {
  color: #000 !important;
}

.timePicker-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timePicker-custom input {
  display: flex;
  align-items: center;
  margin: 0px !important;
  justify-content: center;
}

.timePicker-custom .form-outline .form-control~.form-label {
  top: -3px !important;
}

.autoChehckout .form-check-inline {
  padding-left: 1rem;
}

.timepicker-head .w-100 {
  max-width: 90% !important;
}

.timepicker-head .w-100 .timepicker-submit-inline {
  margin-left: 15px !important;
}

.timepicker-wrapper {
  --mdb-timepicker-elements-min-width: 220px !important;
  max-width: 220px !important;
}

.timepicker-current-inline {
  --mdb-timepicker-current-inline-font-size: 1.5rem !important;
  font-size: var(--mdb-timepicker-current-inline-font-size);
}

.timepicker-head-content .ms-4 {
  margin-left: 15px !important;
  font-size: 1rem !important;
}

.timepicker-head-content .ms-4~.timepicker-pm {
  font-size: 1rem !important;
}

.timepicker-head-content .ms-4~.timepicker-submit {
  font-size: 1.25rem !important;
}

.timepicker-current-wrapper {
  direction: ltr;
  width: 110px;
}

.timepicker-head-content {
  justify-content: center !important;
}

.timepicker-elements-inline {
  min-height: auto;
  max-width: 200px !important;
}

.timepicker-container {
  border-radius: 0.7rem !important;
}

.timepicker-elements-inline {
  border-radius: 0.7rem !important;
} */

.input-group-text.border-0 {
  padding-left: 0px !important;
}

.input-group-text.border-0 {
  padding-left: 0px !important;
}

.input-group {
  flex-wrap: nowrap !important;
}

.datepicker-header {
  background-color: #50c878 !important;
}

.datatable td i {
  color: #636363;
  cursor: pointer;
}

.h37px {
  height: 37px !important;
}

.check-errorTxt {
  font-size: 11px;
  padding-top: 3px;
  line-height: 14px;
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: #50c878 !important;
}


.w-sm-80 {
  width: 80%;
}

.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.discountSelect select {
  width: 70px;
  font-size: 14px;
  height: 26px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 3px;
  color: #656565;
  text-align: left;
}

.sbtn.btn-sm {
  padding: 3px 10px !important;
}

.w-30px {
  width: 40px !important;
}

.react-loading-skeleton {
  --base-color: #eeee !important;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  /* -webkit-user-select: none;
  user-select: none; */
  overflow: hidden;
  z-index: 1;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left-color: transparent !important;
  outline: none !important;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-right-color: transparent !important;
}

.input-group>.form-control:focus {
  transition: all .2s linear;
  border-color: transparent !important;
  outline: 0;
  box-shadow: inset 0 0 0 1px #3b71ca;
}



.Pagenation-Select {
  padding: 6px 10px;
  margin: 0;
  border: none;
  background-color: transparent;
}

.datatable-pagination .ms-2.form-control.w-auto {
  padding: 0px !important;
}

.Pagenation-Select option {
  padding: 5px;
  border: none !important;
}

/* .table {
  width: 100%;
  max-height: calc(100vh - 220px) !important;
}

.table-body {
  height: 100% !important;
  max-height: calc(100vh - 220px) !important;
  overflow-y: auto;
  width: 100%;
} */



.datatable-header {
  position: sticky;
  top: 0px;
}

.datatable,
.datatable table {
  max-height: calc(100vh - 130px);
  overflow-y: scroll;
}

.tab-content .datatable {
  max-height: calc(100vh - 174px);
  overflow-y: scroll;
}

.btn-outline-success:hover {
  color: #14a44d !important;
  background-color: rgba(20, 164, 77, .02);
}

.btn-check:focus+.btn-outline-success,
.btn-check:focus-visible+.btn-outline-success,
.btn-outline-success:first-child:hover,
.btn-outline-success:focus,
.btn-outline-success:focus,
.btn-outline-success:focus-visible,
.btn-outline-success:hover,
:not(.btn-check)+.btn-outline-success:hover {
  border-color: #139c49;
  color: #139c49 !important;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  color: #14a44d !important;
  background-color: transparent;
}

.datatable.reports {
  max-height: calc(100vh - 100px);

}

.loader-height {
  height: 130px;
}

.table-scroll.viewattandance {
  min-height: 32vh;
}


.popupHeight {
  min-height: 45vh;
}

.btn-group-sm>.btn,
.btn-sm {
  --mdb-btn-padding-top: 0.375rem;
  --mdb-btn-padding-bottom: 0.3125rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: 0.75rem;
  --mdb-btn-line-height: 1.5;
}

.btn-group-sm>.btn,
.btn-sm {
  --mdb-btn-padding-top: 0.375rem;
  --mdb-btn-padding-bottom: 0.3125rem;
  --mdb-btn-padding-x: 1rem;
  --mdb-btn-font-size: 0.75rem;
  --mdb-btn-line-height: 1.5;
}

.routing-border {
  border-bottom: 2px solid #ddd;
  margin: 15px 0px;
}

.posMember p,
.form-check-input~label.form-check-label {
  font-size: 12px !important;
  margin-bottom: 0em !important;
}

.form-check-input {
  position: relative;
  width: 1.05rem;
  height: 1.05rem;
  background-color: #fff;
  border: 0.125rem solid rgba(0, 0, 0, .25);
}

.form-check {
  padding-left: 1.05em !important;
}

.gym-payment-bord.payment-board {
  margin: 10px 0px 0px 0px;
  height: calc(100vh - 121px);
}

.addOn-item {
  padding: 0px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 11px;
}

.addOn-item a {
  color: #193b8b !important;
  font-weight: 500;
  display: block;
}

.Addon-Highlight {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 16px;
  position: relative;
  bottom: 0px;
  width: 100%;
}

.Addon-btns {
  height: 50px;
  display: flex;
  align-items: center;
}

.addOn-Details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.addOn-Details .size {
  padding: 5px 7px;
  border-radius: 5px;
  font-size: 11px;
}

.addOn-Details .user-prev-details a {
  padding-right: 0px;
}

.logaTimeIcon {
  margin-bottom: 2px;
}

.attendance_Name {
  font-size: 1.25rem !important;
  font-weight: bold;
}

.btn {
  box-shadow: none !important;
}

.deleteIcon i {
  color: #636363 !important;
}

.addOnButtons {
  height: calc(100vh - 320px);
  overflow: auto;
}

.w-85 {
  width: 85px !important;
}

.d-flex.home-select-drp {
  cursor: pointer;
  background-color: #fff !important;
  text-transform: capitalize;
}

.d-flex.home-select-drp:hover {
  background-color: #f5f5f5 !important;
}

.datepickerBordered {
  border: 1px solid #ccc !important;

}

.logatimeViewatt {
  background: #fff;
  /* margin: 3px 0 2px 0; */
  padding: 0.4em;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #d7dde8;
  border-radius: 5px;
}

.valitTimeCheckin {
  margin-bottom: 10px;
  margin-top: -15px;
  margin-left: 47px;
}

.title {
  text-transform: capitalize;
}

.usedetails {
  display: flex;
  align-items: center;
  margin: 15px 0px;
  flex-wrap: wrap;
}

.usedetails-data {
  margin-right: 0px;
}

thead tr {
  background-color: #E7EEF6 !important;
}

.mmtablediv {
  height: calc(100vh - 180px);
  overflow: auto;
}

.mmtablediv table thead tr {
  position: sticky !important;
  top: -1px;
  z-index: 9;
}

.QRcode {
  width: 150px;
  height: 150px;
}

.QRcode img {
  width: 150px;
}

.checksHeight~.datatable-pagination {
  position: fixed;
  bottom: 0px;
  right: 10px;
}

.reportsDataTable {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 150px);
  overflow: auto;
}

.datatable .scrollbar-container.datatable-inner {
  min-height: calc(100vh - 224px);
  max-height: calc(100vh - 150px);
}

.TransactionsTable {
  height: calc(100vh - 258px);
  overflow: auto;
  position: relative;
}

.TransactionsTable .datatable-header tr {
  position: sticky !important;
  top: -1px !important;
}

.refundsTable .datatable .scrollbar-container.datatable-inner {
  min-height: calc(100vh - 290px);
  max-height: calc(100vh - 150px);
}

.logEvent .datatable .scrollbar-container.datatable-inner {
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
}

.filters_Count {
  /* font-weight: 600; */
  padding: 5px 10px;
  border: 2px solid #ccc;
  color: #000;
  margin-left: 15px;
  border-radius: 7px;
}

.filters_Count:nth-child(1) {
  margin-left: 0px;
}

.SentMailAllert {
  left: 50%;
  transform: translate(-50%, 10px);
}

.txt_wrapper a {
  padding: 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.searchbar-result.gym-container {
  max-height: calc(100vh - 125px);
  overflow: auto;
}

.checksHeight {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

.form-check-label,
.form-check-input {
  margin: 0px !important;
}

.form-check-label {
  margin-left: 5px !important;
}

.form-check {
  display: flex !important;
  align-items: center !important;
}

.dynscrolll::-webkit-scrollbar {
  width: 8px !important;
  height: 3px !important;
}

.searchFilterToggle {
  width: 100%;
  /* height: 90px; */
  display: inline-flex;
  align-items: center;
}

.reportsDataTable .table.datatable-table tr td:nth-child(3) {
  width: 18%;
}

.reportsDataTable .table.datatable-table tr td:nth-child(2) {
  width: 18%;
}

.modal-body.undefined {
  padding: 0.5rem 2rem !important;
  overflow: auto;
}

.clearfix {
  clear: both;
}

.Consentcheckbox textarea {
  border-color: #ccc !important;
}

.logoColor {
  color: #193b8b;
}

.duepaymentradio .splitinput.w-50 {
  width: 100px !important;
}


.card-body.my-0.business_hours .w-100.daysdata ol li.Business_Details_data {
  margin-bottom: 5px !important;
}

.sigCanvas canvas {
  width: 100% !important;
  height: 120px !important;
  /* object-fit: cover  ; */
  margin: 0;
  padding: 0;
}

.popupmember {
  z-index: 100;
  cursor: pointer;
}


.trainerSummeryDataTableNew table tr td,
.trainerSummeryDataTableNew table tr th {
  text-align: center !important;
}

.trainerSummeryDataTable table tr td,
.trainerSummeryDataTable table tr th {
  text-align: center !important;
}

.trainerSummeryDataTable table tr td:first-child,
.trainerSummeryDataTable table tr th:first-child,
.trainerSummeryDataTableNew table tr td:first-child,
.trainerSummeryDataTableNew table tr th:first-child {
  text-align: left !important;
}

.trainerSummeryDataTable table tr td a {
  cursor: pointer;
}

.select-option-text .form-check-input {
  margin-right: var(--mdb-form-outline-select-option-text-form-check-input-margin-right);
  width: 1.125rem !important;
  height: 1.125rem !important;
  position: relative;
  left: 0px;
}

.select-option-text {
  display: flex !important;
  align-items: center !important;
  padding-left: 0px;
  margin-left: -10px;
}

.select-option-text .form-check-input {
  margin-right: 7px !important;
}

/* .trainerMemberSummeryDataTable table tr td,
.trainerMemberSummeryDataTable table tr th {
  text-align: center !important;
} */

.TrainerSummeryCustomHead {
  position: absolute;
  top: 70px;
  right: 20px;
}

.dragBox {
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;

}

.dragBox2 {
  /* height: 250px; */
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;
  padding: 10px 0;
}

.upload__img {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.draging {
  transform: scale(1.1);
}

#preview {
  text-align: center;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
}

#preview img {
  max-width: 100%;
}

.upload-img {
  width: 75px;
  opacity: 0.3;
}

.line-h20 {
  line-height: 20px !important;
}

.upload-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
}

.user-info h5 div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}

.PoweredTxt {
  /* position: absolute; */
  bottom: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.waiver-error-txt {
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
}

.success-animation {
  margin: 40px auto;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

.success-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mob-show {
  display: none;
}

.email-container .gym-logo {
  width: 30%;
}

.email-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.refundsTable .form-outline:nth-child(1),
.sms-gatewaytable .form-outline:nth-child(1) {

  max-width: 25%;

}

.position-relative {
  position: relative !important;
}

.refundsExportButton {
  position: absolute;
  left: 25%;
}

.CheckInBtn-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.email-body .body-text,
.footer-text {
  margin: 0px;
  margin-top: 5px;
}

.download-receipt-button {
  margin: 25px 0px;
  padding: 15px 50px;
  border: none;
  border-radius: 50px;
  background-color: #32408b;
  color: #fff;
}

.email-footer {
  background-color: #070e2f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.email-footer-icons img {
  width: 25px;
}



.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.vw-5 {
  vwidth: 5vw !important;
}

.vw-10 {
  vwidth: 10vw !important;
}

.vw-15 {
  vwidth: 15vw !important;
}

.vw-20 {
  vwidth: 20vw !important;
}

.vw-25 {
  vwidth: 25vw !important;
}

.vw-30 {
  vwidth: 30vw !important;
}

.vw-35 {
  vwidth: 35vw !important;
}

.vw-40 {
  vwidth: 40vw !important;
}

.vw-45 {
  vwidth: 45vw !important;
}

.vw-50 {
  vwidth: 50vw !important;
}

.vw-55 {
  vwidth: 55vw !important;
}

.vw-60 {
  vwidth: 60vw !important;
}

.vw-65 {
  vwidth: 65vw !important;
}

.vw-70 {
  vwidth: 70vw !important;
}

.vw-75 {
  vwidth: 75vw !important;
}

.vw-80 {
  vwidth: 80vw !important;
}

.vw-85 {
  vwidth: 85vw !important;
}

.vw-90 {
  vwidth: 90vw !important;
}

.vw-95 {
  vwidth: 95vw !important;
}

.vw-100 {
  vwidth: 100vw !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

.vh-5 {
  height: 5vh !important;
}

.vh-10 {
  height: 10vh !important;
}

.vh-15 {
  height: 15vh !important;
}

.vh-20 {
  height: 20vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-35 {
  height: 35vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-45 {
  height: 45vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-55 {
  height: 55vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-65 {
  height: 65vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-85 {
  height: 85vh !important;
}

.vh-90 {
  height: 90vh !important;
}

.vh-95 {
  height: 95vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-150px {
  width: 150px !important;
}

.ql-editor {
  max-height: 200px;
}



.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 70px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PhoneInputInput {
  border: none;
  padding: 0px !important;
}

/* .suspendaccess {

  visibility: hidden;
} */


.pos-submittedwaivers .form-check-input~label.form-check-label {
  margin-top: 1px !important;
  font-size: 14px !important;
}

.mob-waiver-text {
  margin-right: 25px;
}

.addmembersearch {
  position: absolute;
  top: 10px;
  left: 38px;
  width: 43%;
}

.form-control.PhoneInput {
  display: flex;
}

.Business__input__adjust .PhoneInputCountry img {
  margin-top: 0px;
}

.form-control.PhoneInput .PhoneInputCountry {
  /* width: 100px; */
}

.PhoneInputCountryIcon--border {
  box-shadow: none;
}

.smsTemplateTable .datatable-table tr td {
  vertical-align: text-top;
  white-space: normal;
}

.fs-small {
  font-size: 0.75em !important;
}

.chip {
  text-transform: capitalize !important;
  --mdb-chip-height: auto !important;
}

.chip.expiry {
  color: #000 !important;
  text-transform: capitalize !important;
  --mdb-chip-height: 24px;
  padding: 0 8px;
}

.Business__Details__data label {
  text-transform: capitalize;
}

.custom_date_picker div div input {
  max-width: fit-content !important;
  float: right !important;
}

.pauseplan .w-30 input {
  width: 100% !important;
}

@media screen and (max-width: 991.9px) {

  .usersearch {
    position: absolute !important;
    width: 125px;
    min-height: 40px;
    max-height: 150px;
    z-index: 9999;
    overflow-y: auto;
  }

  .nav-tabs {
    border-bottom: 0;
    width: 100%;
  }


  .gym-main {
    width: 99%;
    margin: auto !important;
    margin-top: 30px !important;
  }

  .gym-cnr {
    margin-top: 10px !important;
  }

  .user-info {
    position: static;
    height: auto !important;
  }

  .logoImg {
    width: 50% !important;
    height: auto;
  }

  .gym-white,
  .gym-hover-white.active {
    border-radius: 0px !important;
  }

  .card-body.my-0.business_hours .d-flex.schedules {
    width: 100% !important;
    display: block !important;
  }

  .card-body.my-0.business_hours .w-200px.dayslabel {
    width: 100% !important;
    display: flex !important;
    font-size: 1.3em;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .card-body.my-0.business_hours .w-100.daysdata {
    width: 100% !important;
    display: block !important;
  }

  .modal.fade.show {
    padding-right: 0px !important;
  }

  .gym-xlarge.gym-larg-pos {
    display: block !important;
  }

  .dashboard_links.d-flex {
    display: block !important;
    height: auto !important;
  }

  .dashboard_links.d-flex a {
    cursor: pointer;
    font-size: 14px;
    color: #000 !important;
    padding: 5px !important;
    display: inline-block;
  }

  .dashboard_links .customRange {
    /* display: block !important; */
    width: 100% !important
  }

  .dashboard_links.d-flex .active {
    color: var(--mdb-link-color) !important;
    background: #f5f5f5;
    padding: 10px !important;
    margin: 5px !important;
    border-radius: 15px;
  }

  .posSearchData_2 {
    width: 20% !important;
  }

  .mob-block.d-flex .ms-3 {
    margin-left: 0 !important;
    font-weight: 400;
    font-size: 14px;
  }

  .mob-block.d-flex,
  .mob-block {
    display: block !important;
  }

  .mob-block.d-flex .ms-3,
  .mob-block.d-flex .ms-2 {
    margin-top: 10px !important;
  }

  .POStitle.d-flex {
    display: block !important;
  }


  .refundsTable .form-outline:nth-child(1) {
    max-width: 70% !important;
  }

  .refundsExportButton {
    position: absolute;
    left: 70% !important;
  }

  .mob-m-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mob-m-0.ms-3 {
    margin-left: 0px !important;
    margin: 5px !important;
  }

  .duepaymentradio {
    display: block;
  }

  .duepaymentradio .splitinput.w-50 {
    width: 35% !important;
  }

  .paymt-clr-Multiple .w-25,
  .paymt-clr-1 .w-25,
  .paymt-clr-2 .w-25,
  .paymt-clr-3 .w-25,
  .paymt-clr-4 .w-25,
  .paymt-clr-5 .w-25,
  .paymt-clr-6 .w-25 {
    width: 100% !important;
  }

  .paymt-clr-Multiple,
  .paymt-clr-1,
  .paymt-clr-2,
  .paymt-clr-3,
  .paymt-clr-4,
  .paymt-clr-5,
  .paymt-clr-6 {
    padding: 12px !important;
  }


  .d-flex.justify-content-between.align-items-center.posMember {
    display: block !important;
  }

  .d-flex.justify-content-between.align-items-center.posMember * {
    display: inline-block !important;
  }

  .d-flex.justify-content-between.align-items-center.posMember .deleteIcon {
    position: absolute;
    margin-top: -32px;
    right: 20px;
    padding: 10px;
    text-align: center;
  }

  .inp-clrdate-Small {
    width: 150px !important;
  }

  .inp-clrdate-Small.w-85 {
    width: 85px !important;
  }

  .pointonsale-widgets,
  .point_sale_addOn {
    min-height: auto !important;
    max-height: calc(100vh - 0px) !important;
    padding-bottom: 0em !important;
  }

  .gym-payment-bord.payment-board {
    margin: 0px 0px 0px 0px !important;
    min-height: calc(100vh - 200px) !important;
    height: auto !important;
    max-height: calc(100vh - 0px) !important;
  }

  .addOnButtons {
    min-height: calc(100vh - 200px) !important;
    min-height: auto !important;
    height: auto !important;
    max-height: calc(100vh - 0px) !important;
    overflow: auto;
  }

  .payment-board.gym-payment-bord .w-90 {
    width: 100% !important;
  }

  .pricingDiv .pricingPlan {
    width: calc(100% - 0%);
  }

  .btn.btn-icon {
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 18px !important;
  }

  .greenoverlay {
    display: none;
  }

  .CheckInBtn-align {
    display: block !important;
  }

  .mob-w-10 {
    width: 10% !important;
  }

  .mob-w-20 {
    width: 20% !important;
  }

  .mob-w-30 {
    width: 30% !important;
  }

  .mob-w-40 {
    width: 40% !important;
  }

  .mob-w-50 {
    width: 50% !important;
  }

  .mob-w-60 {
    width: 60% !important;
  }

  .mob-w-70 {
    width: 70% !important;
  }

  .mob-w-80 {
    width: 80% !important;
  }

  .mob-w-90 {
    width: 90% !important;
  }

  .mob-w-95 {
    width: 95% !important;
  }

  .mob-w-100 {
    width: 100% !important;
  }

  .mob-mt-1 {
    margin-top: 0.25rem !important;
  }

  .mob-mt-2 {
    margin-top: 0.5rem !important;
  }

  .mob-mt-3 {
    margin-top: 1rem !important;
  }

  .mob-mt-4 {
    margin-top: 1.5rem !important;
  }

  .mob-mt-5 {
    margin-top: 3rem !important;
  }

  .Addon-btns {
    position: fixed;
    bottom: 10px;
    z-index: 99;
    background-color: #fff;
    width: 100vw;
    text-align: center;
    padding: 5px 0;
  }

  .mob-addons {
    height: auto !important;
    padding-bottom: 16em;
  }

  .Addon-Highlight {
    position: sticky !important;
    width: 100%;
  }

  .close-icon-m {
    cursor: pointer;
    position: fixed;
    right: calc(100vw - 280px);
    top: 2px;
    width: 25px;
    height: 25px;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    margin: 20px 0 0px 0px;
  }

  .checkindate.checkinbuttonout p {
    line-height: 1 !important;
  }

  .gym-button:hover {
    color: #000 !important;
    background-color: transparent !important;
  }

  .POStitle .mob-block.d-flex {
    display: flex !important;
    width: 100%;
  }

  .posMember p {
    font-size: 12px !important;
    margin-bottom: 0em !important;
    display: block;
    width: 100% !important;
  }

  .printpayment div div.d-flex img:first-child {
    width: 80px;
    text-align: center;
  }

  .printpayment div div.d-flex .ml30,
  .printpayment div div.d-flex .ml30 div {
    width: 100%;
    text-align: center;
    display: block;
  }

  .printpayment div div.d-flex .ml30 .paymentsuccess-numbr,
  .printpayment div div.d-flex .ml30 .paymentsuccess-numbr {
    width: auto;
    text-align: center;
    display: inline-block;
  }

  .printpayment div div.d-flex {
    width: 100%;
    text-align: center;
    display: block !important;
  }

  .printpayment div div.d-flex * {
    width: 100%;
    /* text-align: center; */
    margin: 0;
  }

  .printpayment div div.d-flex * .checkinbuttonmain {
    width: 100%;
    text-align: left !important;
    margin: 0;
  }

  .mob-trans-btns {
    display: block !important;
    float: left;
    margin-bottom: 1em;
  }

  .mob-trans-btns * {
    float: left;
    position: static;
  }

  .mob-trans-btns .txt_wrapper * {
    margin: 0 2px !important;
  }

  .datatable-pagination {
    flex-direction: row-reverse !important;
    padding: 10px !important;
  }

  .datatable-pagination .Pagenation-Select {
    padding: 0.2em;
    width: 60px !important;
  }

  .datatable-pagination .datatable-pagination-button {
    padding: 0.5em !important;
  }

  .datatable-pagination .w-50px {
    padding: 0.2em;
    width: 40px !important;
  }

  .checksHeight~.datatable-pagination {
    position: fixed;
    bottom: 0px;
    right: 0px;
    text-align: center;
    font-size: 0.875em;
  }

  .createplanmain.w-100 {
    display: flex;
    justify-content: space-between;
  }

  .createplanmain.w-100 .gym-large {
    display: block !important;
    width: 100% !important;
  }

  .checkinbuttonout p.d-flex.m-inherit {
    text-align: center;
    display: block !important;
  }

  .view-boxdate {
    margin: 5px 0;
  }

  .slotpopups {
    width: fit-content;
    padding: 0px 0px 0px 0px;
  }

  .createplanmain.w-100 .mobile-d-flex {
    display: inline-block !important;
  }

  .createplanmain.w-100 .mobile-d-flex a.p-1 {
    display: inline-block !important;
    width: 50%;
  }

  .allowedslotsmain-boxactive {
    /* background: #50c878; */
    color: #ffffff;
    padding: 2px;
    border: 1px solid #dbdbdb;
    margin: 0 5px 5px 0;
    width: 48%;
  }

  .slottime {
    font-size: 12px;
  }

  .business-form .popup-bussiness .gym-section.gym-half {
    width: 100% !important;
  }

  .gym-section.order-1.ms-3~.mob-w-100.ms-3.mob-m-0 {
    margin-left: 5px !important;
    padding-right: 5px;
  }

  .PoweredTxt {
    position: relative !important;
    bottom: 10px;
    padding: 10px;
  }

  .business-form .gym-section.gym-half.flex-column,
  .gym-section.gym-half.flex-column {
    width: 100% !important;
    flex-direction: row !important;
    align-items: center;
    padding: 0 !important;
    display: flex;
  }

  .business-form .gym-section.gym-half.flex-column .switchlabel,
  .business-form .gym-section.gym-half.flex-column label.mb-3 {
    margin-bottom: 0 !important;
  }

  .gym-section.gym-half.flex-column .switchlabel,
  .gym-section.gym-half.flex-column label.mb-3 {
    margin-bottom: 0 !important;
  }

  /* .switchlabel {
    width: 50%;
  } */

  .switchlabel~.switch {
    margin-left: 0 !important;
  }

  .business-form .gym-section {
    width: 100% !important;
    padding: 0 5px !important;
  }

  .mobileResloution .gym-section {
    width: 46%;
    padding: 0px !important;
    margin: 5px !important;
  }

  .card-body.my-0.business_hours .w-100.daysdata ol {
    margin: 0 15px !important;
  }

  .card-body.my-0.business_hours .w-100.daysdata ol li.Business_Details_data {
    margin-bottom: 5px !important;
  }

  .card-body.my-0.business_hours .w-200px.dayslabel .switch {
    margin-right: 0px !important;
  }

  .BusinessPage__content .Business__Details__data label:first-child,
  .BusinessPage__content .Business__Details__data label:nth-child(2) {
    width: 100% !important;
  }

  .BusinessPage__content .Business__input__adjust,
  .BusinessPage__content select {
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0;
  }

  .mob-inline-block.d-flex,
  .mob-inline-block.d-flex .chip,
  .mob-inline-block {
    display: inline-block !important;
  }

  .mob-inline-block.d-flex .chip {
    line-height: 34px;
  }

  .mob-inline-block.d-flex .chip .close {
    top: 8px;
    position: relative;
  }

  .createplanmain.w-100 .mobile-d-flex .createplan {
    width: 100%;
  }

  .dashboard_note.pt-3 {
    padding-top: 10px !important;
  }

  .mob-div-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* 
  .datatable-pagination {
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
  } */

  .gym-xlarge {
    /* font-size: 16px !important; */
  }

  .posMemberMobile {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
  }

  .business_Info_page .card-body {
    padding: 1rem !important;
  }

  .mt-22pxmobile {
    margin-top: 22px;
  }

  .modal-body-overflow {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .form-controlMobile {
    display: block;
    width: 100% !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #4f4f4f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    appearance: none;
    border-radius: .25rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    transition: all .2s linear;
  }

  .form-controlMobile:focus {
    box-shadow: none;
    transition: all .1s linear;
    border-color: #3b71ca;
    box-shadow: inset 0px 0px 0px 1px #3b71ca;
  }

  .form-selectMobile {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #4f4f4f;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%234f4f4f%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #bdbdbd;
    border-radius: .25rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
    transition: all .2s linear;
    appearance: none;
  }

  .form-selectMobile:focus {
    border-color: #3b71ca;
    outline: 0;
    box-shadow: inset 0px 0px 0px 1px #3b71ca;
  }

  .gym-section.w-100-mobile {
    width: 100%;
  }

  .gym-section.w-50-mobile {
    width: 50%;
  }

  .gym-section.w-50-mobile.ms-3 {
    float: left;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 10px 0px 10px !important;
  }

  .gym-section.w-100-mobile.ms-3,
  .gym-section.w-100-mobile {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 10px 0px 10px !important;
  }

  .filterApplyMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    margin-bottom: 20px;
  }

  .filterApplyMobile .mt-2.btn.btn-secondary {
    margin-top: 0px !important;
  }

  .mmtablediv {
    height: auto !important;
  }

  .TrainerSummeryCustomHead {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    top: 0px !important;
    right: 0px !important;
    font-size: 0.85rem !important;
  }


  .trainerSummeryFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 0px 10px;
  }

  .trainerSummeryFilters .select-wrapper {
    width: 100%;
    margin-left: 10px;
  }

  .filters_Count:nth-child(2) {
    margin-left: 10px !important;
  }

  .mobile-Head {
    display: flex;
    flex-direction: column;
  }

  .mobile-Head .text-center {
    order: 3;
    padding-bottom: 10px;
  }

  .mobile-Head .Waiver-logo {
    order: 1;
  }

  .mobile-Head .routing-border {
    order: 2;
  }

  .datatable table th,
  .datatable table td .trans-date {
    white-space: break-spaces !important;
    vertical-align: text-top;
  }

  .datatable table td .d-flex {
    display: inline-block !important;
    vertical-align: text-top;
  }

  .datatable-inner {
    overflow: auto !important;
  }



  .mob-waiver-text {
    margin-top: 50px;
    width: fit-content;
  }

  .addmembersearch {
    position: absolute;
    top: 15px;
    left: 18px !important;
    width: 90% !important;
  }

  .label-offset.w-55 {
    padding-left: 0 !important;
  }

  .datatable .datatable-inner {
    min-height: 50vh;
  }

  .emailTemplate.d-flex {
    flex-direction: column;
  }

  .emailTemplate.d-flex .px-5 {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }

  .emailTemplate .w-50 {
    width: 100% !important;
  }

  .emailTemplate .w-50:first-child {
    order: 2;
  }

  .modal-body {
    max-height: 90vh !important;
  }

  .modal-body .mainbox.dynscrolll {
    max-height: 65vh !important;
  }

  .modal-body .mainboxsecond {
    margin-top: 0 !important;
  }

  .btn {
    padding: 6px 5px !important;
    font-size: 12px !important;
    height: auto !important;
  }

  table tbody tr:last-child td {
    vertical-align: text-top;
  }

  tbody tr:last-child td {
    vertical-align: text-top;
  }

  .datatable tbody tr:last-child {
    border-bottom: transparent;
    height: 50px !important;
  }
}


.react-datepicker-popper {
  z-index: 999 !important;
}

.timer {
  line-height: normal;
}

.timer span {
  font-size: 12px !important;
}

.checkinbuttonout.mob-block.mob-w-100 {
  line-height: normal;
}

.datatable tbody tr:last-child,
.datatable tbody tr:last-child {
  border-bottom: transparent;
  --mdb-datatable-tbody-tr-last-child-height: normal !important;
}

.chip.payment-span {
  padding: 7px 10px;
  display: inline-block;
  --mdb-chip-margin-right: 3px !important;
  margin-right: var(--mdb-chip-margin-right) !important;
  --mdb-chip-line-height: normal;
}

.chip.payment-span label {
  display: inline-block;
}

.card-title .badge {
  font-size: 11px;
}

.timepicker-icon-down,
.timepicker-icon-up {
  opacity: 1 !important;
}


.ErrorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
  flex-direction: column;
}

.unplug_img {
  width: 500px;
}

.business_Info_page#contact,
.table-scroll#contact {
  padding: 0px !important;
}

.col-4.gym-section.gym-half .form-check-label {
  font-size: 14px !important;
}

.cover-user .cover-user-img {
  min-height: 100vh;
  height: auto;
}

.greenoverlay {
  min-height: 100vh;
  height: 100%;
}

.leftpanelHeigt {
  min-height: calc(100vh - 224px);
}

.w_200px {
  min-width: 200px;
}


.w-80px {
  width: 80px !important;
}

tbody tr td div .btn.btn-sm {
  padding: 3px 10px !important;
  font-size: 8px !important;
  height: auto !important;
}


.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4CAF50;
}

.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #FFFFFF;
  transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #FFFFFF;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/*----------------------*/
.button-link {
  background: none;
  border: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.button-text {
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.base-timer {
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 5px;
  stroke: #efefef;
}

.base-timer__path-remaining {
  stroke-width: 4px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #39b37d;
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.line-height-normal {
  line-height: normal !important;
}


.msg-settings-table .datatable table td,
.msg-settings-table .datatable table th {
  padding: .6rem !important;
  text-align: center;
}

.msg-settings-table .datatable tr th:first-child,
.msg-settings-table .datatable tr td:first-child {
  text-align: left !important;
}

.templateEditor {
  height: calc(100vh - 140px);
}

.animated-panel {
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  max-height: 0;
}

.show-panel {
  max-height: calc(100vh - 140px);
}

.top_12px {
  top: 12px !important;
}

.switch input[type="checkbox"] {
  border: none !important;
}

.txt-success {
  color: #139c49;
}


.sms-settings-table.custon_DT tr th:nth-child(1),
.sms-settings-table.custon_DT tr td:nth-child(1) {
  min-width: auto !important;
}

.sms-settings-table.custon_DT .datatable table th,
.sms-settings-table.custon_DT .datatable table td {
  white-space: normal;
  overflow: visible;
  line-height: 1.6;
  text-overflow: clip;
  max-width: var(--mdb-datatable-table-th-td-max-width);
  padding: var(--mdb-datatable-table-th-td-padding-y) var(--mdb-datatable-table-th-td-padding-x);
}

.sms-settings-table.custon_DT .datatable-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.sms-settings-table.custon_DT.smsTable .datatable tr th:nth-child(2),
.sms-settings-table.custon_DT.smsTable .datatable tr td:nth-child(2),
.sms-settings-table.custon_DT.smsTable .datatable tr th:nth-child(3),
.sms-settings-table.custon_DT.smsTable .datatable tr td:nth-child(3),
.sms-settings-table.custon_DT.smsTable .datatable tr th:nth-child(4),
.sms-settings-table.custon_DT.smsTable .datatable tr td:nth-child(4),
.sms-settings-table.custon_DT.smsTable .datatable tr th:nth-child(5),
.sms-settings-table.custon_DT.smsTable .datatable tr td:nth-child(5) {
  width: auto;
  text-align: center;
}

.sms-settings-table.custon_DT .datatable tr th:nth-child(2),
.sms-settings-table.custon_DT .datatable tr td:nth-child(2) {
  width: 40%;
}

.cursor-normal i {
  cursor: default !important;
}

.failure-animation .cross {
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: draw 1s forwards;
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

.crossmark__circle {
  stroke: #ff4d4f;
  stroke-width: 2;
}

.crossmark__cross {
  stroke: #ff4d4f;
  stroke-width: 5;
  stroke-linecap: round;
}

.failure-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.failure-animation {
  width: 100px;
  height: 100px;
}

.paymentLoader {
  width: 100px;
  height: 100px;
  position: relative;
}

.PaymentSpinner {
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top: 10px solid #50c878;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-body.whatsapptemplateBody {
  max-height: 80vh !important;
}

.whatsAppTemplate {
  max-height: 100px;
  overflow-y: auto;
}

.notificationFrequencyDatatable .datatable table td,
.notificationFrequencyDatatable.datatable table th {
  padding: 1rem !important;
}

.scrollsend {
  max-height: 200px;
  overflow: auto !important;
}

.fs-7 {
  font-size: 0.7rem;
}

.trainerSummeryDataTable.payments .datatable .datatable-inner table thead tr th:not(:first-child),
.trainerSummeryDataTable.payments .datatable .datatable-inner table tbody tr td:not(:first-child) {
  text-align: right !important;
}


.trainerSummeryDataTable.plan .datatable .datatable-inner table thead tr th:not(:first-child),
.trainerSummeryDataTable.plan .datatable .datatable-inner table tbody tr td:not(:first-child) {
  text-align: right !important;
}

.summeryReportsDatatable .datatable .datatable-inner table thead tr th:nth-child(6),
.summeryReportsDatatable .datatable .datatable-inner table tbody tr td:nth-child(6),
.summeryReportsDatatable .datatable .datatable-inner table thead tr th:nth-child(7),
.summeryReportsDatatable .datatable .datatable-inner table tbody tr td:nth-child(7),
.summeryReportsDatatable .datatable .datatable-inner table thead tr th:nth-child(8),
.summeryReportsDatatable .datatable .datatable-inner table tbody tr td:nth-child(8) {
  text-align: center !important;
}

.datatable.reports table tr th:first-child,
.datatable.reports table tr td:first-child {
  text-align: center;
}

.invoiceDownload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}



#ms-container {
  width: 100%;
  height: 100vh;
  background: #50c878 !important;
}

.ms-content {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 10px solid #c7ffda99;
  border-radius: 100%;
}

.ms-content-inside {
  width: 100%;
  height: 100%;
  position: relative;
}

.ms-line-down-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotate(0deg);
  transition: transform 0s ease;
}

#ms-download {
  display: none;
}

#ms-download:checked~.ms-line-down-container {
  transform: rotate(-360deg);
  transition: transform 1.5s ease 1.25s;
}

.ms-line-down {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 125px;
  background: #FFFFFF;
  border-radius: 50px;
  transition: height .5s ease;
}

#ms-download:checked~.ms-line-down-container .ms-line-down {
  height: 10px;
  top: -20px;
  animation: ms-bounce .5s forwards .55s;
}

#ms-download:checked~.ms-line-down-container .ms-line-down:before {
  content: '';
  position: absolute;
  top: 0;
  left: -94px;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 200px;
  border: 10px solid transparent;
  border-radius: 100%;
  animation: ms-border-fill .5s forwards 3s;
}

.ms-line-point {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 58px;
  height: 130px;
  transition: height .15s ease .45s;
}

#ms-download:checked~.ms-line-point {
  height: 60px;
}

#ms-download:checked~.ms-line-point:before {
  transform: rotate(90deg);
  right: -10px;
  animation: ms-check-right .25s forwards 3s;
}

#ms-download:checked~.ms-line-point:after {
  transform: rotate(-90deg);
  left: -10px;
  animation: ms-check-left .25s forwards 3s;
}

.ms-line-point:before,
.ms-line-point:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 75px;
  bottom: -10px;
  background: #FFFFFF;
  border-radius: 50px;
  transition: transform .15s ease .47s, left .15s ease .47s, right .15s ease .47s;
}

.ms-line-point:before {
  right: 0px;
  transform: rotate(45deg);
}

.ms-line-point:after {
  left: 0px;
  transform: rotate(-45deg);
}

@keyframes ms-bounce {
  0% {
    top: -20px;
  }

  25% {
    top: -250px;
  }

  50% {
    top: -275px;
  }

  100% {
    top: -190px;
  }
}

@keyframes ms-check-left {
  0% {
    transform: rotate(-90deg);
    left: -10px;
    height: 75px;
  }

  100% {
    transform: rotate(-45deg);
    left: 0px;
    height: 45px;
  }
}

@keyframes ms-check-right {
  0% {
    transform: rotate(90deg);
    right: -10px;
    height: 75px;
    bottom: -10px;
  }

  100% {
    transform: rotate(45deg);
    right: 10px;
    height: 80px;
    bottom: -15px;
  }
}

@keyframes ms-border-fill {
  0% {
    border: 10px solid transparent;
  }

  100% {
    border: 10px solid #FFFFFF;
  }
}

.downladTxt {
  color: #FFF;
  text-align: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.textarea {
  border-radius: 4px;
}

.whatsapptable table tr th:nth-child(1),
.whatsapptable table tr td:nth-child(1) {
  width: 40px !important;
  max-width: 40px !important;
  text-align: center;
}

.whatsapptable table tr th:nth-child(2),
.whatsapptable table tr td:nth-child(2) {
  min-width: 200px !important;
  width: 200px !important;
}

.whatsapptable table tr th:nth-child(3),
.whatsapptable table tr td:nth-child(3) {
  min-width: 70px !important;
  width: 100px !important;
}

.whatsapptable table tr th:nth-child(4),
.whatsapptable table tr td:nth-child(4) {
  text-align: center;
  min-width: 70px !important;
  width: 100px !important;
}

.whatsapptable table tr th:nth-child(5),
.whatsapptable table tr td:nth-child(5) {
  overflow: unset;
  white-space: normal;
}

.recipientdetails {
  max-height: 200px;
  overflow-y: scroll;
}

.whatsapphistorytable table tr td:last-child {
  overflow: unset;
  white-space: normal;
}

.sno table tr th:first-child,
.sno table tr td:first-child {
  text-align: center;
}

.nav-tabs .nav-item a {
  padding: 10px 10px;
}

input::placeholder {
  font-weight: 400 !important;
}

.tooltip {
  --mdb-tooltip-zindex: 1050 !important;
}

.whatsapp {
  text-transform: inherit !important;
}

.WhatsAppMembersTable table tr th:nth-child(5),
.WhatsAppMembersTable table tr td:nth-child(5),
.WhatsAppMembersTable table tr th:nth-child(1),
.WhatsAppMembersTable table tr td:nth-child(1),
.plansTable table tr th:nth-child(6),
.plansTable table tr td:nth-child(6),
.plansTable table tr th:nth-child(7),
.plansTable table tr td:nth-child(7),
.PlansDataTable table tr th:nth-child(4),
.PlansDataTable table tr td:nth-child(4),
.PlansDataTable table tr th:nth-child(5),
.PlansDataTable table tr td:nth-child(5),
.discountsDataTable table tr th:nth-child(4),
.discountsDataTable table tr td:nth-child(4),
.discountsDataTable table tr th:nth-child(5),
.discountsDataTable table tr td:nth-child(5),
.TransactionsTable table tr th:nth-child(10),
.TransactionsTable table tr td:nth-child(10),
.refundsDataTable table tr th:nth-child(9),
.refundsDataTable table tr td:nth-child(9) {
  text-align: center;
}


.RevinueReportTable table tr th:nth-child(5),
.RevinueReportTable table tr td:nth-child(5) {
  text-align: right;
}


.pymentsTable table tr th:nth-child(6),
.pymentsTable table tr td:nth-child(6),
.pymentsTable table tr th:nth-child(7),
.pymentsTable table tr td:nth-child(7),
.pymentsTable table tr th:nth-child(8),
.pymentsTable table tr td:nth-child(8),
.PlansDataTable table tr th:nth-child(9),
.PlansDataTable table tr td:nth-child(9),
.addOnsDataTable table tr th:nth-child(4),
.addOnsDataTable table tr td:nth-child(4),
.TransactionsTable table tr th:nth-child(7),
.TransactionsTable table tr td:nth-child(7),
.TransactionsTable table tr th:nth-child(8),
.TransactionsTable table tr td:nth-child(8),
.TransactionsTable table tr th:nth-child(9),
.TransactionsTable table tr td:nth-child(9),
.refundsDataTable table tr th:nth-child(4),
.refundsDataTable table tr td:nth-child(4),
.refundsDataTable table tr th:nth-child(5),
.refundsDataTable table tr td:nth-child(5) {
  text-align: right;
}

.timepicker-input {
  margin-bottom: 0px !important;
}

.w-150px {
  width: 150px !important;
}

.textTimeLabel .input-group-text {
  width: 40px !important;
}

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
}

.inputEdit {
  width: 230px !important;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0;
  font-size: 12px;
}

.GroupingCardHeight {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

.select-option-text {
  display: block !important;
  padding-left: 0px;
  margin-left: -10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
}

.form-control:focus::placeholder {
  opacity: 1 !important;
  /* Keep the placeholder visible even on focus */
}

.customnavtabs .nav-item {
  border-right: 2px solid #ccc;
}

.customnavtabs .nav-item:last-child {
  border-color: transparent !important;
}

@media (min-width: 991px) and (max-width: 1366px) {
  .gymlogo1 {
    margin-bottom: 0;
    padding-left: 0;
    width: 250px;

  }

  .gymlogo1.mt-md-5 {
    margin-top: 0 !important;
  }

  .mt-3.w-100.recapthca>div>div {
    width: 250px !important;
  }

  form.login-form iframe[title="reCAPTCHA"] {
    transform: scale(0.85);
    margin-left: -20px;
  }
}

.white-nowrap {
  white-space: nowrap;
}

.PhoneInputCountrySelect option[value="ZZ"] {
  display: none;
}