/* Extra small devices (phones) */
@media (max-width: 575px) {

    /* Your styles for extra small screens */
    .home-select-drp {
        display: flex;
        align-items: flex-start !important;
        justify-content: space-between !important;
        padding: 5px 10px !important;
    }

    .posSearchData_1 {
        width: 75%;
    }

    .posSearchData_2 {
        width: 15%;
    }

    .timegym-home .card-body {
        padding: 15px !important;
        justify-content: flex-start !important;
    }

    .posMobileView {
        flex-wrap: wrap !important;
    }

}

/* Small devices (tablets) */
@media (min-width: 576px) and (max-width: 767px) {
    /* Your styles for small screens */
}

/* Medium devices (laptops, desktops) */
@media (min-width: 768px) and (max-width: 991px) {
    /* Your styles for medium screens */
}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
    /* Your styles for large screens */
}

/* Extra large devices (large desktops) */
@media (min-width: 1200px) and (max-width: 1399px) {
    /* Your styles for extra large screens */
}

/* Extra extra large devices (extra large desktops) */
@media (min-width: 1400px) {
    /* Your styles for extra extra large screens */
}